import { useState, useEffect } from 'react';
import useFilterStorage from '../../home/hooks/useFiltersStorage';

const DEFAULT = '';
const KEY = 'poolsBySearchTerm';

const useFilterBySearchTerm = pools => {
  const { getStorage, setStorage } = useFilterStorage();
  const data = getStorage(KEY);
  const [searchTerm, setSearchTerm] = useState(data ? data : DEFAULT);

  useEffect(() => {
    setStorage(KEY, searchTerm);
  }, [setStorage, searchTerm]);
  let filteredBySearchTerm = pools;
  if (searchTerm !== DEFAULT) {
    //
    //console.log(searchTerm);
    //console.log(pools);
    filteredBySearchTerm = pools.filter(pool =>
      pool.assets.some(x => x.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
    );
  }

  return { filteredBySearchTerm, searchTerm, setSearchTerm };
};

export default useFilterBySearchTerm;
