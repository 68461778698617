import React, { useState, useEffect } from 'react';
import useNightMode from '../../features/home/hooks/useNightMode';
import { ReactComponent as YourSvg } from 'assets/img/background-vector.svg';
import { ReactComponent as YourSvgLight } from 'assets/img/background-vector--light.svg';

const BackgroundCustom = ({ total, index }) => {
  const [dimensions, setDimensions] = useState(null);
  const {
    isNightMode,
    // setNightMode,
  } = useNightMode();

  useEffect(() => {
    const r = 100 / total;
    const x = (index + Math.random() * 0.5) * r;
    const y = Math.ceil(Math.random() * 30) / 10 + 0.5;
    const w = Math.round(Math.random() * 5) + 2.25;
    const d = Math.random() < 0.5 ? -1 : 1;

    setDimensions({ x, y, w, d });
  }, [total, index]);

  if (!dimensions) return null;
  return isNightMode ? (
    <YourSvg
      className="responsive-background"
      style={{
        position: 'absolute',
        opacity: '0.7',
      }}
    ></YourSvg>
  ) : (
    <YourSvgLight
      className="responsive-background"
      style={{
        position: 'absolute',
        opacity: '0.7',
      }}
    ></YourSvgLight>
  );
};

export default BackgroundCustom;
