import { container } from 'assets/jss/material-kit-pro-react.js';

const appStyle = theme => ({
  '@global': {
    'html,body': {
      backgroundColor: theme.palette.backgroundCustom.color,
      backgroundImage: `url(data:image/svg+xml;base64,${theme.palette.backgroundCustom.image})`,
      backgroundSize: '100% auto',
      backgroundRepeat: 'no-repeat',
      color: theme.palette.text.primary,
    },
  },
  page: {
    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden',
  },
  container: {
    ...container,
    zIndex: 1,
  },
  children: {
    minHeight: '77vh',
  },
});

export default appStyle;
