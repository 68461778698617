import { chefABI } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

export const deposit = async ({
  web3,
  address,
  isAll,
  amount,
  contractAddress,
  dispatch,
  pid,
  referrer,
}) => {
  const contract = new web3.eth.Contract(chefABI, contractAddress);
  const data = await _deposit({ web3, contract, isAll, amount, address, dispatch, pid, referrer });
  return data;
};

const _deposit = ({ web3, contract, amount, _isAll, address, dispatch, pid, referrer }) => {
  const _referrer = referrer ? referrer : '0x0000000000000000000000000000000000000000';
  // console.log({ _referrer, pid, amount, context: 'deposit' });
  return new Promise((resolve, reject) => {
    contract.methods
      .deposit(pid, amount, _referrer)
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    // }
  });
};
