import { chefABI } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

export const harvest = async ({ web3, address, contractAddress, dispatch, pid, isEarly }) => {
  const contract = new web3.eth.Contract(chefABI, contractAddress);
  const data = await _harvest({ contract, address, dispatch, pid, isEarly });
  return data;
};

const _harvest = ({ contract, address, dispatch, pid, isEarly }) => {
  let contractCall;
  if (isEarly) {
    contractCall = contract.methods.withdrawEarly(pid, 0);
  } else {
    contractCall = contract.methods.withdraw(pid, 0);
  }
  return new Promise((resolve, reject) => {
    contractCall
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};
