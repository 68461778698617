import React, { useState, useMemo, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import Grid from '@material-ui/core/Grid';
import BigNumber from 'bignumber.js';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import CustomSlider from 'components/CustomSlider/CustomSlider';
import RefundButtons from '../RefundButtons/RefundButtons';
import Countdown from 'react-countdown';
import { useFetchLocker } from '../../../redux/fetchLocker';
import { byDecimals, convertAmountToRawNumber } from 'features/helpers/bignumber';
import {
  useFetchWithdraw,
  useFetchBalances,
  useFetchApproval,
  useFetchZapEstimate,
} from 'features/vault/redux/hooks';
import { useConnectWallet } from 'features/home/redux/hooks';
import { getNetworkCoin } from 'features/helpers/getNetworkData';
import { useFetchHarvestFee } from 'features/vault/redux/hooks';
import styles from './styles';

import { useDialogState } from 'features/vault/redux/hooks';

const useStyles = makeStyles(styles);
const nativeCoin = getNetworkCoin();

const WithdrawSection = ({ pool, index }) => {
  const { t } = useTranslation();
  const { fetchUserLocker } = useFetchLocker();
  const classes = useStyles();
  const { web3, address } = useConnectWallet();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { harvestFeeUntil } = useFetchHarvestFee();
  // const { lockedUntil, fetchLockedUntilPending, fetchLockedUntil } = useFetchLockedUntil();
  const {
    fetchWithdraw,
    fetchWithdrawBnb,
    fetchZapWithdrawAndRemoveLiquidity,
    fetchZapWithdrawAndSwap,
    fetchWithdrawPending,
  } = useFetchWithdraw();
  const { fetchZapWithdrawEstimate, fetchZapEstimatePending } = useFetchZapEstimate();
  const { tokens, fetchBalances, fetchPairReverves } = useFetchBalances();
  const harvestFeeDue = harvestFeeUntil[pool.id] ? harvestFeeUntil[pool.id] * 1000 : 0;
  //new Date(pool.lockedUntil * 1000);
  const lockedUntil = pool.lockedUntil ? pool.lockedUntil * 1000 : 0;
  const sharesDecimals = pool.tokenDecimals;
  const depositedAmount = byDecimals(pool.staked, pool.tokenDecimals);

  const withdrawOutputs = useMemo(() => {
    const outputs = [
      {
        name: pool.name,
        symbol: pool.token,
        address: pool.tokenAddress,
        decimals: pool.tokenDecimals,
      },
    ];

    if (pool.zap) {
      const pairTokens = pool.zap.tokens.filter(t => t.symbol !== nativeCoin.wrappedSymbol);
      if (pairTokens.length) {
        outputs.push(
          {
            symbol: pool.assets.join('+'),
          },
          ...pairTokens
        );
      }
    }

    return outputs;
  }, [pool.assets, pool.name, pool.token, pool.tokenAddress, pool.tokenDecimals, pool.zap]);

  // const isBeli = pool.token === 'BELI';
  const isShowLockUntil = pool.token.includes('BELI');
  // due.setMilliseconds(pool.lockedUntil);
  const fee = pool.withdrawalFee ? pool.withdrawalFee : '0';
  const {
    // dialogState,
    updateDialogState,
  } = useDialogState();
  const farm = {
    withdrawalFeeBP: pool.harvestFee,
    // withdrawalFeeInterval: 8,
  };

  const handleClickOpen = () => {
    updateDialogState(
      true,
      () => (
        <>
          <img
            alt="launchpool how to"
            src={require('assets/img/warning.png')}
            style={{ width: '100px', marginBottom: '20px' }}
          />
          <div className={classes.dialogtext} style={{ marginBottom: '20px', fontSize: '26px' }}>
            Warning!
          </div>
          <div style={{ textAlign: 'center', padding: '0 20px', fontSize: '16px' }}>
            <span>
              Withdraw before the lock timer has ended will trigger{' '}
              {lockedUntil > 0 && (
                <span>
                  <b className={classes.hightLightFee}>{fee}%</b> withdrawal fee
                </span>
              )}
              {lockedUntil > 0 && harvestFeeDue > Date.now() ? ' + ' : '. '}
              {harvestFeeDue > Date.now() && (
                <span>
                  <b className={classes.hightLightFee}>{farm.withdrawalFeeBP}%</b> harvest fee.{' '}
                </span>
              )}
            </span>
            Are you sure?
          </div>
        </>
      ),
      'Yes',
      onWithDraw
    );
  };

  const show2digitsAtLeast = n => {
    return (n + '').replace(/^([0-9])$/, '0$1');
  };

  const rendererWithdrawCountdown = ({ hours, minutes, seconds, days }) => {
    return (
      <div className={classes.timeLeft} style={{ textAlign: 'center' }}>
        {depositedAmount.gt(0) &&
          (parseInt(hours) > 0 ||
            parseInt(minutes) > 0 ||
            parseInt(seconds) > 0 ||
            parseInt(days) > 0) && (
            <div style={{ marginTop: '1rem' }}>
              <div className={classes.largeText}>
                {show2digitsAtLeast(days)}:{show2digitsAtLeast(hours)}:{show2digitsAtLeast(minutes)}
                :{show2digitsAtLeast(seconds)}
              </div>
              Time left
              <br />
              <br />
            </div>
          )}
        <i>
          {pool.token === 'BELI' && (
            <span>
              Single ${pool.name} staking vault yields more BELI tokens through the distribution of
              protocol revenue
            </span>
          )}
          {depositedAmount.gt(0) && (
            <span>
              <br />
              <br />
              Unstaking {pool.name} before the lock timer has ended will trigger{' '}
              {lockedUntil > 0 && (
                <span>
                  <b className={classes.hightLightFee}>{fee}%</b> withdrawal fee{' '}
                </span>
              )}
              {lockedUntil > 0 && harvestFeeDue > Date.now() ? '+ ' : ''}
              {harvestFeeDue > Date.now() && (
                <span>
                  <b className={classes.hightLightFee}>{farm.withdrawalFeeBP}%</b> harvest fee
                </span>
              )}
            </span>
          )}
        </i>
      </div>
    );
  };

  const [withdrawSettings, setWithdrawSettings] = useState({
    isZap: false,
    isSwap: false,
    swapInput: undefined,
    swapOutput: undefined,
    outputIndex: 0,
    amount: new BigNumber(0),
    slider: 0,
    input: '0.0',
    vaultAddress: pool.earnedTokenAddress,
    withdrawAddress: pool.earnContractAddress,
    isNeedApproval: false,
    slippageTolerance: 0.01,
    swapAmountOut: pool.zapWithdrawEstimate?.swapAmountOut,
    pid: pool.pid,
  });

  // useEffect(() => {
  //   if (web3 && address && !fetchLockedUntilPending) {
  //     fetchLockedUntil({ web3, address, pool });
  //   }
  // }, [web3, address, pool, fetchLockedUntil, fetchLockedUntilPending]);

  useDeepCompareEffect(() => {
    if (fetchWithdrawPending[index]) return;
    if (fetchZapEstimatePending[pool.tokenAddress]) return;
    if (pool.zap) {
      fetchPairReverves({ web3, pairToken: tokens[pool.token] });
    }
  }, [pool, new Date().getMinutes()]);

  useDeepCompareEffect(() => {
    if (fetchWithdrawPending[index]) return;
    if (fetchZapEstimatePending[pool.tokenAddress]) return;
    if (pool.zap && withdrawSettings.isSwap) {
      fetchZapWithdrawEstimate({
        web3,
        vaultAddress: pool.earnContractAddress,
        routerAddress: pool.zap.ammRouter,
        swapInput: withdrawSettings.swapInput,
        swapOutput: withdrawSettings.swapOutput,
        pairToken: tokens[pool.token],
        pairTokenAmount: convertAmountToRawNumber(
          withdrawSettings.amount,
          tokens[pool.token].decimals
        ),
      });
    }
  }, [tokens[pool.token].reserves, withdrawSettings.amount, withdrawSettings.outputIndex]);

  const handleOutputChange = event => {
    const outputIndex = event.target.value;
    const isZap = outputIndex > 0;
    const isSwap = outputIndex > 1;
    const spender = isZap ? pool.zap.zapAddress : pool.earnContractAddress;
    const swapInput = isSwap ? withdrawOutputs[outputIndex === 2 ? 3 : 2] : undefined;
    const swapOutput = isSwap ? withdrawOutputs[outputIndex] : undefined;
    const allowance = new BigNumber(tokens[pool.earnedToken].allowance[spender]);

    setWithdrawSettings(prevState => ({
      ...prevState,
      outputIndex,
      isZap,
      isSwap,
      swapInput,
      swapOutput,
      withdrawAddress: spender,
      isNeedApproval: isZap && allowance.isZero(),
    }));
  };

  const handleSliderChange = (_, sliderInt) => {
    setWithdrawSettings(prevState => ({
      ...prevState,
      slider: sliderInt,
    }));
  };

  const handleSliderChangeCommitted = (_, sliderInt) => {
    let amount = new BigNumber(0);
    let input = new BigNumber(0);
    if (sliderInt > 0 && sliderInt < 99) {
      amount = depositedAmount
        .times(sliderInt)
        .div(100)
        .decimalPlaces(pool.tokenDecimals, BigNumber.ROUND_DOWN);
      input = amount.decimalPlaces(8, BigNumber.ROUND_DOWN).toFormat();
    }
    if (sliderInt >= 99) {
      amount = depositedAmount;
      sliderInt = 100;
      input = amount.toFormat();
    }

    setWithdrawSettings(prevState => ({
      ...prevState,
      amount: amount,
      slider: sliderInt,
      input: input,
    }));
  };

  const handleMax = _ => {
    handleSliderChangeCommitted(_, 100);
  };

  const handleInputAmountChange = event => {
    const input = event.target.value.replace(/[,]+/, '').replace(/[^0-9.]+/, '');
    let amount = new BigNumber(input);

    if (amount.isNaN()) amount = new BigNumber(0);
    if (amount.isGreaterThan(depositedAmount)) amount = depositedAmount;

    const sliderInt = depositedAmount.isZero()
      ? 0
      : amount.times(100).dividedToIntegerBy(depositedAmount).toNumber();

    setWithdrawSettings(prevState => ({
      ...prevState,
      amount: amount,
      slider: sliderInt,
      input: amount.isEqualTo(input) ? input : amount.toFormat(),
    }));
  };

  useEffect(() => {
    const allowance = new BigNumber(
      tokens[pool.earnedToken].allowance[withdrawSettings.withdrawAddress]
    );
    setWithdrawSettings(prevState => ({
      ...prevState,
      isNeedApproval: prevState.isZap && allowance.isZero(),
    }));
  }, [pool.earnedToken, tokens, withdrawSettings.withdrawAddress]);

  const handleApproval = () => {
    fetchApproval({
      address,
      web3,
      tokenAddress: pool.earnContractAddress,
      contractAddress: pool.zap.zapAddress,
      tokenSymbol: pool.earnedToken,
    })
      .then(() => enqueueSnackbar(t('Vault-ApprovalSuccess'), { variant: 'success' }))
      .catch(error => enqueueSnackbar(t('Vault-ApprovalError', { error }), { variant: 'error' }));
  };

  const handleWithdraw = () => {
    if (+depositedAmount.toFormat(8) > 0 && (pool.isTimelocked || harvestFeeDue > Date.now())) {
      handleClickOpen();
    } else {
      onWithDraw();
    }
  };
  const onWithDraw = () => {
    const sharesAmount = withdrawSettings.amount.decimalPlaces(sharesDecimals, BigNumber.ROUND_UP);
    if (sharesAmount.times(100).dividedBy(depositedAmount).isGreaterThan(99)) {
      return handleWithdrawAll();
    }
    withdraw(convertAmountToRawNumber(sharesAmount, sharesDecimals));
  };

  const handleWithdrawAll = () => {
    const isAll = true;
    setWithdrawSettings(prevState => ({
      ...prevState,
      amount: depositedAmount,
      input: depositedAmount.toFormat(),
      slider: 100,
    }));
    withdraw(convertAmountToRawNumber(depositedAmount, sharesDecimals), isAll);
  };

  const withdraw = (sharesAmount, isAll = false) => {
    if (withdrawSettings.isZap) {
      if (withdrawSettings.isSwap) {
        const swapAmountOut = pool.swapEstimate.amountOut;
        const swapAmountOutMin = new BigNumber(
          swapAmountOut - swapAmountOut * withdrawSettings.slippageTolerance
        );
        const zapWithdrawArgs = {
          address,
          web3,
          vaultAddress: pool.earnContractAddress,
          amount: sharesAmount,
          zapAddress: pool.zap.zapAddress,
          tokenOut: withdrawSettings.swapOutput.address,
          amountOutMin: swapAmountOutMin.toFixed(0),
        };
        fetchZapWithdrawAndSwap(zapWithdrawArgs)
          .then(() => {
            fetchUserLocker({ web3, address });
            enqueueSnackbar(t('Vault-WithdrawSuccess'), { variant: 'success' });
            fetchBalances({ address, web3, tokens });
          })
          .catch(error =>
            enqueueSnackbar(t('Vault-WithdrawError', { error }), { variant: 'error' })
          );
      } else {
        const zapWithdrawArgs = {
          address,
          web3,
          vaultAddress: pool.earnContractAddress,
          amount: sharesAmount,
          zapAddress: pool.zap.zapAddress,
        };
        fetchZapWithdrawAndRemoveLiquidity(zapWithdrawArgs)
          .then(() => {
            fetchUserLocker({ web3, address });
            enqueueSnackbar(t('Vault-WithdrawSuccess'), { variant: 'success' });
            fetchBalances({ address, web3, tokens });
          })
          .catch(error =>
            enqueueSnackbar(t('Vault-WithdrawError', { error }), { variant: 'error' })
          );
      }
    } else {
      const isEarly = pool.lockedUntil > Math.round(Date.now() / 1000);
      const vaultWithdrawArgs = {
        address,
        web3,
        isAll,
        amount: sharesAmount,
        contractAddress: pool.earnContractAddress,
        index,
        pid: pool.pid,
        isEarly,
      };
      if (pool.tokenAddress) {
        fetchWithdraw(vaultWithdrawArgs)
          .then(() => {
            fetchUserLocker({ web3, address });
            enqueueSnackbar(t('Vault-WithdrawSuccess'), { variant: 'success' });
            fetchBalances({ address, web3, tokens });
          })
          .catch(error =>
            enqueueSnackbar(t('Vault-WithdrawError', { error }), { variant: 'error' })
          );
      } else {
        fetchWithdrawBnb(vaultWithdrawArgs)
          .then(() => {
            fetchUserLocker({ web3, address });
            enqueueSnackbar(t('Vault-WithdrawSuccess'), { variant: 'success' });
            fetchBalances({ address, web3, tokens });
          })
          .catch(error =>
            enqueueSnackbar(t('Vault-WithdrawError', { error }), { variant: 'error' })
          );
      }
    }
  };

  return (
    <Grid item xs={12} md={4} className={classes.sliderDetailContainer}>
      <div className={classes.subtitle} style={{ marginBottom: '8px' }}>
        {t('Vault-Deposited')}:{' '}
        <a onClick={handleMax} className={classes.subtitle} style={{ cursor: 'pointer' }}>
          {depositedAmount > 0 ? depositedAmount.toFormat(8) : 0} {pool.token}
        </a>
      </div>
      <FormControl fullWidth variant="outlined">
        <CustomOutlinedInput
          fullWidth
          value={withdrawSettings.input}
          onChange={handleInputAmountChange}
          endAdornment={
            pool.zap && (
              <FormControl className={classes.zapFormControl}>
                <Select
                  variant="standard"
                  className={classes.zapSelect}
                  value={withdrawSettings.outputIndex}
                  onChange={handleOutputChange}
                >
                  {withdrawOutputs.map((output, i) => (
                    <MenuItem key={i} value={i}>
                      {output.symbol}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
        />
      </FormControl>
      <CustomSlider
        aria-labelledby="continuous-slider"
        value={withdrawSettings.slider}
        onChange={handleSliderChange}
        onChangeCommitted={handleSliderChangeCommitted}
      />
      <div>
        {pool.refund === true ? (
          <RefundButtons
            tokenAddress={pool.earnedTokenAddress}
            refundAddress={pool.refundContractAddress}
            index={index}
          />
        ) : (
          <div style={{ textAlign: 'center', marginBottom: '36px', marginTop: '16px' }}>
            {withdrawSettings.isNeedApproval ? (
              fetchApprovalPending[pool.earnedToken] ? (
                <span className={classes.url}>{t('Vault-Approving')}</span>
              ) : (
                <a
                  className={classes.url}
                  style={{ color: '#2196f3' }}
                  href="javascript:void(0)"
                  onClick={handleApproval}
                >
                  {' '}
                  {t('Vault-ApproveButton')}{' '}
                </a>
              )
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  marginBottom: '36px',
                  marginTop: '16px',
                }}
              >
                {pool.depositsPaused ||
                withdrawSettings.amount.isZero() ||
                fetchZapEstimatePending[pool.tokenAddress] ||
                fetchWithdrawPending[index] ? (
                  <span className={classes.url}>
                    {fetchWithdrawPending[index]
                      ? `${t('Vault-Withdrawing')}`
                      : `${t('Vault-WithdrawButton')}`}
                  </span>
                ) : (
                  <a
                    className={classes.url}
                    style={{ color: '#2196f3' }}
                    href="javascript:void(0)"
                    onClick={handleWithdraw}
                  >
                    {' '}
                    {t('Vault-WithdrawButton')}{' '}
                  </a>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {pool.isTimelocked && isShowLockUntil ? (
        <Countdown renderer={rendererWithdrawCountdown} date={lockedUntil}></Countdown>
      ) : (
        ''
      )}
    </Grid>
  );
};

export default WithdrawSection;
