import React from 'react';
import Grid from '@material-ui/core/Grid';

import DepositSection from '../PoolDetails/DepositSection/DepositSection';
import WithdrawSection from '../PoolDetails/WithdrawSection/WithdrawSection';
import HarvestSection from '../PoolDetails/HarvestSection/HarvestSection';
import { NetworkRequired } from '../../../../components/NetworkRequired/NetworkRequired';

const PoolActions = ({ pool, balanceSingle, index, pendingRewards, pendingFeeReceiver, pendingConvertedEarn, fetchRewardsPending }) => {
  return (
    <NetworkRequired inline>
      <Grid container>
        <HarvestSection
          index={index}
          pool={pool}
          pendingRewards={pendingRewards}
          pendingConvertedEarn={pendingConvertedEarn}
          pendingFeeReceiver={pendingFeeReceiver}
          fetchRewardsPending={fetchRewardsPending}
        />
        <DepositSection index={index} pool={pool} balanceSingle={balanceSingle} />
        <WithdrawSection index={index} pool={pool} />
      </Grid>
    </NetworkRequired>
  );
};

export default PoolActions;
