import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useTranslation } from 'react-i18next';
import { getNetworkPools } from '../../features/helpers/getNetworkData';

import styles from './styles';
import { formatTvl } from '../../features/helpers/format';
import { useTheme } from '@material-ui/core/styles';
import ContentLoader from 'react-content-loader';
import { fetchPrice, fetchCountry } from 'features/web3';

const useStyles = makeStyles(styles);

const HeaderTokenPrice = ({
  connected,
  address,
  connectWallet,
  disconnectWallet,
  isNightMode,
  setNightMode,
}) => {
  const classes = useStyles();
  // const { t } = useTranslation();
  // const [shortAddress, setShortAddress] = useState('');
  // const [dataUrl, setDataUrl] = useState(null);
  // const canvasRef = useRef(null);
  const [country, setCountry] = useState(null);
  const [price, setPrice] = useState(0);
  const theme = useTheme();
  const pools = getNetworkPools();
  const beliPool = pools.find(p => p.id === 'beli-staking');
  const [tokenUrl, setTokenUrl] = useState(beliPool.buyTokenUrl);

  const job = setInterval(() => {
    const newPrice = fetchPrice({ id: 'BELI' });
    if (newPrice !== price) {
      clearInterval(job);
      setPrice(newPrice);
    }
    const newCountry = fetchCountry();
    if (newCountry !== country) {
      setCountry(newCountry);
      if (newCountry === 'ID') {
        setTokenUrl('https://p2p.beli.finance/');
      } else {
        setTokenUrl(beliPool.buyTokenUrl);
      }
    }
  }, 3000);

  return (
    <div
      className="token-price-wrapper"
      style={{
        border: '1px solid rgba(255, 255, 255, 0.2)',
        boxSizing: 'border-box',
        backdropFilter: 'blur(4px)',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span
        style={{
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '24px',
        }}
        className={classes.textColor}
      >
        BELI
      </span>
      <span
        style={{
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '36px',
          marginLeft: '8px',
        }}
        className={classes.textColor}
      >
        {price ? (
          formatTvl(1, price)
        ) : (
          <ContentLoader
            width={50}
            height={28}
            viewBox="0 0 50 28"
            backgroundColor={theme.palette.background.primary}
            foregroundColor={theme.palette.background.hover}
            style={{ verticalAlign: 'middle' }}
          >
            <rect x="0" y="0" width="50" height="28" />
          </ContentLoader>
        )}
      </span>
      <span
        style={{
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#FFFFFF',
          marginLeft: '8px',
        }}
      >
        <a href={tokenUrl} target="_blank" rel="noopener noreferrer">
          Buy
        </a>
      </span>
    </div>
  );
};

export default HeaderTokenPrice;
