import { lockerABI } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

const LOCKER_ADDRESS = '0x337e095f9ca2d13e70d95847ed0a625b0413bd89';

export const unlockLocker = async ({ address, web3, dispatch }) => {
  const lockerContract = new web3.eth.Contract(lockerABI, LOCKER_ADDRESS);
  await lockerContract.methods.unlock().estimateGas({ from: address }).catch(console.error);
  return new Promise((resolve, reject) => {
    lockerContract.methods
      .unlock()
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};
