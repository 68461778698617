import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    DIALOG_STATE_CHANGE
} from './constants';

export function updateDialogState(isOpen, contentRenderer, confirmButtonTitle, confirmButtonCallback) {
  return dispatch => {
    dispatch({
      type: DIALOG_STATE_CHANGE,
      data: {
          isOpen, contentRenderer, confirmButtonTitle, confirmButtonCallback
      }
    });
  };
}

export function useDialogState() {
  const dispatch = useDispatch();

  const { dialogState } = useSelector(
    state => ({
      dialogState: state.vault.dialogState,
    }),
    shallowEqual
  );

  const boundAction = useCallback((isOpen, contentRenderer, confirmButtonTitle, confirmButtonCallback) => {
    dispatch(updateDialogState(isOpen, contentRenderer, confirmButtonTitle, confirmButtonCallback));
  }, [dispatch]);

  return {
    dialogState,
    updateDialogState: boundAction
  };
}

/*
action.data:
 - isOpen
 - title
 - secondButtonTitle
 - mainButtonTitle
 - mainButtonCallback
*/

export function reducer(state, action) {
  switch (action.type) {
    case DIALOG_STATE_CHANGE:
      return {
        ...state,
        dialogState: action.data,
      };

    default:
      return state;
  }
}
