export const DIALOG_STATE_CHANGE = 'DIALOG_STATE_CHANGE';
export const VAULT_FETCH_BALANCES_BEGIN = 'VAULT_FETCH_BALANCES_BEGIN';
export const VAULT_FETCH_BALANCES_SUCCESS = 'VAULT_FETCH_BALANCES_SUCCESS';
export const VAULT_FETCH_BALANCES_FAILURE = 'VAULT_FETCH_BALANCES_FAILURE';

export const VAULT_FETCH_VAULTS_DATA_BEGIN = 'VAULT_FETCH_VAULTS_DATA_BEGIN';
export const VAULT_FETCH_VAULTS_DATA_SUCCESS = 'VAULT_FETCH_VAULTS_DATA_SUCCESS';
export const VAULT_FETCH_VAULTS_DATA_FAILURE = 'VAULT_FETCH_VAULTS_DATA_FAILURE';

export const VAULT_FETCH_STAKE_DATA_BEGIN = 'VAULT_FETCH_STAKE_DATA_BEGIN';
export const VAULT_FETCH_STAKE_DATA_SUCCESS = 'VAULT_FETCH_STAKE_DATA_SUCCESS';
export const VAULT_FETCH_STAKE_DATA_FAILURE = 'VAULT_FETCH_STAKE_DATA_FAILURE';

export const VAULT_FETCH_APPROVAL_BEGIN = 'VAULT_FETCH_APPROVAL_BEGIN';
export const VAULT_FETCH_APPROVAL_SUCCESS = 'VAULT_FETCH_APPROVAL_SUCCESS';
export const VAULT_FETCH_APPROVAL_FAILURE = 'VAULT_FETCH_APPROVAL_FAILURE';

export const VAULT_FETCH_DEPOSIT_BEGIN = 'VAULT_FETCH_DEPOSIT_BEGIN';
export const VAULT_FETCH_DEPOSIT_SUCCESS = 'VAULT_FETCH_DEPOSIT_SUCCESS';
export const VAULT_FETCH_DEPOSIT_FAILURE = 'VAULT_FETCH_DEPOSIT_FAILURE';

export const VAULT_FETCH_WITHDRAW_BEGIN = 'VAULT_FETCH_WITHDRAW_BEGIN';
export const VAULT_FETCH_WITHDRAW_SUCCESS = 'VAULT_FETCH_WITHDRAW_SUCCESS';
export const VAULT_FETCH_WITHDRAW_FAILURE = 'VAULT_FETCH_WITHDRAW_FAILURE';

export const VAULT_FETCH_APYS_BEGIN = 'VAULT_FETCH_APYS_BEGIN';
export const VAULT_FETCH_APYS_SUCCESS = 'VAULT_FETCH_APYS_SUCCESS';
export const VAULT_FETCH_APYS_FAILURE = 'VAULT_FETCH_APYS_FAILURE';

export const VAULT_FETCH_STRATEGY_HARVEST_BEGIN = 'VAULT_FETCH_STRATEGY_HARVEST_BEGIN';
export const VAULT_FETCH_STRATEGY_HARVEST_SUCCESS = 'VAULT_FETCH_STRATEGY_HARVEST_SUCCESS';
export const VAULT_FETCH_STRATEGY_HARVEST_FAILURE = 'VAULT_FETCH_STRATEGY_HARVEST_FAILURE';

export const VAULT_FETCH_ZAP_ESTIMATE_BEGIN = 'VAULT_FETCH_ZAP_ESTIMATE_BEGIN';
export const VAULT_FETCH_ZAP_ESTIMATE_SUCCESS = 'VAULT_FETCH_ZAP_ESTIMATE_SUCCESS';
export const VAULT_FETCH_ZAP_ESTIMATE_FAILURE = 'VAULT_FETCH_ZAP_ESTIMATE_FAILURE';

export const VAULT_FETCH_BIFIBUYBACK_BEGIN = 'VAULT_FETCH_BIFIBUYBACK_BEGIN';
export const VAULT_FETCH_BIFIBUYBACK_SUCCESS = 'VAULT_FETCH_BIFIBUYBACK_SUCCESS';
export const VAULT_FETCH_BIFIBUYBACK_FAILURE = 'VAULT_FETCH_BIFIBUYBACK_FAILURE';

export const VAULT_FETCH_REWARDS_BEGIN = 'VAULT_FETCH_REWARDS_BEGIN';
export const VAULT_FETCH_REWARDS_SUCCESS = 'VAULT_FETCH_REWARDS_SUCCESS';
export const VAULT_FETCH_REWARDS_FAILURE = 'VAULT_FETCH_REWARDS_FAILURE';

export const VAULT_UPDATE_LAUNCHPOOLS = 'VAULT_UPDATE_LAUNCHPOOLS';

export const VAULT_FETCH_USER_LOCKER_BEGIN = 'VAULT_FETCH_USER_LOCKER_BEGIN';
export const VAULT_FETCH_USER_LOCKER_SUCCESS = 'VAULT_FETCH_USER_LOCKER_SUCCESS';
export const VAULT_FETCH_USER_LOCKER_FAILURE = 'VAULT_FETCH_USER_LOCKER_FAILURE';

export const VAULT_FETCH_GLOBAL_LOCKER_BEGIN = 'VAULT_FETCH_GLOBAL_LOCKER_BEGIN';
export const VAULT_FETCH_GLOBAL_LOCKER_SUCCESS = 'VAULT_FETCH_GLOBAL_LOCKER_SUCCESS';
export const VAULT_FETCH_GLOBAL_LOCKER_FAILURE = 'VAULT_FETCH_GLOBAL_LOCKER_FAILURE';

export const VAULT_FETCH_UNLOCK_LOCKER_BEGIN = 'VAULT_FETCH_UNLOCK_LOCKER_BEGIN';
export const VAULT_FETCH_UNLOCK_LOCKER_SUCCESS = 'VAULT_FETCH_UNLOCK_LOCKER_SUCCESS';
export const VAULT_FETCH_UNLOCK_LOCKER_FAILURE = 'VAULT_FETCH_UNLOCK_LOCKER_FAILURE';

export const VAULT_FETCH_HARVEST_FEE_BEGIN = 'VAULT_FETCH_HARVEST_FEE_BEGIN';
export const VAULT_FETCH_HARVEST_FEE_SUCCESS = 'VAULT_FETCH_HARVEST_FEE_SUCCESS';
export const VAULT_FETCH_HARVEST_FEE_FAILURE = 'VAULT_FETCH_HARVEST_FEE_FAILURE';

export const VAULT_FETCH_LOCKED_UNTIL_BEGIN = 'VAULT_FETCH_LOCKED_UNTIL_BEGIN';
export const VAULT_FETCH_LOCKED_UNTIL_SUCCESS = 'VAULT_FETCH_LOCKED_UNTIL_SUCCESS';
export const VAULT_FETCH_LOCKED_UNTIL_FAILURE = 'VAULT_FETCH_LOCKED_UNTIL_FAILURE';
