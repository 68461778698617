import { primaryColor } from 'assets/jss/material-kit-pro-react.js';

const styles = theme => ({
  sliderDetailContainer: {
    padding: '20px 20px',
  },
  dialogbg: {
    backgroundColor: theme.palette.background.dialogCustom,
  },
  dialogtext: {
    color: theme.palette.text.dialogCustom,
  },
  dialogbuttontext: {
    color: theme.palette.text.dialogCustomButton,
  },
  showDetailLeft: {
    float: 'left',
    marginBottom: '10px',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    fontWeight: '500',
  },
  showDetailButtonCon: {
    display: 'flex',
    justifyContent: 'space-around',
    '& + &': {
      marginLeft: '5px',
    },
  },
  showPausedMsg: {
    display: 'flex',
    margin: '12px 5px',
    padding: '15px',
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '5px',
    background: `${theme.palette.background.paused}`,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  showRetiredMsg: {
    display: 'flex',
    margin: '12px 5px',
    padding: '15px',
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '5px',
    background: `${theme.palette.background.retired}`,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  showDetailButton: {
    margin: '12px 5px',
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '5px',
    width: '160px',
  },
  showDetailButtonOutlined: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    color: primaryColor[0],
    '&:hover': {
      '& .MuiButton-label': {
        color: 'white',
      },
    },
    '& .MuiTouchRipple-root span': {
      backgroundColor: primaryColor[0],
    },
  },
  showDetailButtonContained: {
    backgroundColor: primaryColor[0],
    '& .MuiButton-label': {
      color: 'white',
    },
  },
  numericInput: {
    color: primaryColor[0],
  },
  title: {
    fontSize: '20px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    lineHeight: '18px',
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
    lineHeight: '18px',
  },
  feeSubtitle: {
    fontSize: '12px',
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
    lineHeight: '15px',
    textAlign: 'center',
  },
  url: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    letterSpacing: 0,
    '&:hover,&:focus': {
      //color: theme.palette.text.secondary,
    },
  },
  note: {
    textAlign: 'center',
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
  balanceMax: {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    borderBottom: '1px dashed',
  },
  zapNote: {
    width: '100%',
    textAlign: 'left',
    fontSize: '14px',
    color: theme.palette.text.secondary,
  },
  zapFormControl: {
    minWidth: 'auto',
  },
  zapSelect: {
    border: 'none',
    fontSize: '14px',
    borderLeft: `1px solid ${theme.palette.text.secondary}`,
    paddingLeft: '10px',
    '& > div': {
      padding: '6px 0',
    },
    '&::before': {
      content: 'none',
    },
    '&::after': {
      content: 'none',
    },
  },
});

export default styles;
