import React, { memo, useCallback, useEffect, useMemo } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Accordion, AccordionDetails } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './styles';
import { assets, platforms } from './constants';

const useStyles = makeStyles(styles);

const Filters = ({
  toggleFilter,
  filters,
  platform,
  vaultType,
  asset,
  order,
  setPlatform,
  setVaultType,
  setAsset,
  setOrder,
  searchTerm,
  setSearchTerm,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handlePlatformChange = useCallback(event => setPlatform(event.target.value), [setPlatform]);
  const handleVaultTypeChange = useCallback(
    event => setVaultType(event.target.value),
    [setVaultType]
  );
  const handleAssetChange = useCallback((_event, option) => setAsset(option.value), [setAsset]);
  const handleOrderChange = useCallback(event => setOrder(event.target.value), [setOrder]);
  const handleSearchTermChange = useCallback(
    event => setSearchTerm(event.target.value),
    [setSearchTerm]
  );

  const allAssetOptions = useMemo(() => {
    return [
      {
        value: 'All',
        label: t('Filters-All'),
      },
      ...assets.map(asset => ({
        value: asset,
        label: asset,
      })),
    ];
  }, [t]);

  const resetFilter = useCallback(() => {
    toggleFilter('resetAll');
    setPlatform('All');
    setVaultType('All');
    setAsset('All');
    setOrder('default');
    setSearchTerm('');
  }, [toggleFilter, setPlatform, setVaultType, setAsset, setOrder, setSearchTerm]);

  useEffect(() => {
    if ((!asset || !allAssetOptions.find(option => option.value === asset)) && asset !== 'All') {
      setAsset('All');
    }
  }, [allAssetOptions, asset, setAsset]);

  useEffect(() => {
    if ((!platform || !platforms.includes(platform)) && platform !== 'All') {
      setPlatform('All');
    }
  }, [platform, setPlatform]);

  const Control = () => (
    <>
      <Grid item xs={6} sm={4} md={3}>
        <FormControl className="checkbox-label">
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                checked={filters.hideZeroBalances}
                onChange={() => toggleFilter('hideZeroBalances')}
                color="primary"
                size="small"
              />
            }
            // TODO: translate labels
            label={t('Hide-Zero-Balances')}
          />
        </FormControl>
      </Grid>

      <Grid item xs={7} sm={4} md={3}>
        <FormControl className="checkbox-label">
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                checked={!filters.hideDecomissioned}
                onChange={() => toggleFilter('hideDecomissioned')}
                color="primary"
                size="small"
              />
            }
            // TODO: translate labels
            label={t('Retired-Vaults')}
          />
        </FormControl>
      </Grid>

      <Grid item xs={7} sm={4} md={3}>
        <FormControl className="checkbox-label">
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                checked={filters.hideZeroVaultBalances}
                onChange={() => toggleFilter('hideZeroVaultBalances')}
                color="primary"
                size="small"
              />
            }
            // TODO: translate labels
            label={t('Hide-Zero-Vault-Balances')}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6} sm={4} md={3}></Grid>

      <Grid item xs={12} sm={4} md={3}>
        <InputLabel id="select-platform-label" className={classes.selectorLabel}>
          {t('Filters-Platform')}
        </InputLabel>
        <FormControl className={classes.selectorContainer}>
          <Select
            value={platform}
            onChange={handlePlatformChange}
            className={classes.selector}
            variant="outlined"
            margin={'dense'}
            id="select-platform"
            labelId="select-platform-label"
          >
            <MenuItem className={classes.menuItem} key={'All'} value={'All'}>
              {t('Filters-All')}
            </MenuItem>
            {platforms.map(platform => (
              <MenuItem className={classes.menuItem} key={platform} value={platform}>
                {platform}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4} md={3}>
        <InputLabel id="select-vault-type-label" className={classes.selectorLabel}>
          {t('Filters-Vault-Type')}
        </InputLabel>
        <FormControl className={classes.selectorContainer}>
          <Select
            value={vaultType}
            onChange={handleVaultTypeChange}
            className={classes.selector}
            variant="outlined"
            margin={'dense'}
            id="select-vault-type"
            labelId="select-vault-type-label"
          >
            <MenuItem className={classes.menuItem} key={'All'} value={'All'}>
              {t('Filters-All')}
            </MenuItem>
            <MenuItem className={classes.menuItem} key={'Singles'} value={'Singles'}>
              {t('Filters-Type-SingleAssets')}
            </MenuItem>
            <MenuItem className={classes.menuItem} key={'StableLPs'} value={'StableLPs'}>
              {t('Filters-Type-StableLPs')}
            </MenuItem>
            <MenuItem className={classes.menuItem} key={'Stables'} value={'Stables'}>
              {t('Filters-Type-Stables')}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4} md={3}>
        <InputLabel id="select-vault-type-label" className={classes.selectorLabel}>
          {t('Filters-Asset')}
        </InputLabel>
        <FormControl className={classes.selectorContainer}>
          <Autocomplete
            value={allAssetOptions.find(option => option.value === asset)}
            onChange={handleAssetChange}
            className="TextField-without-border-radius menuItem"
            id="select-asset"
            options={allAssetOptions}
            getOptionLabel={option => option.label}
            renderOption={option => <span className={classes.menuItem}>{option.label}</span>}
            renderInput={params => (
              <TextField
                {...params}
                margin={'dense'}
                variant="outlined"
                style={{ marginTop: 0, borderRadius: '30px' }}
              />
            )}
            disableClearable
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4} md={3}>
        <InputLabel id="select-order-label" className={classes.selectorLabel}>
          {t('Filters-Sort')}
        </InputLabel>
        <FormControl className={classes.selectorContainer}>
          <Select
            value={order}
            onChange={handleOrderChange}
            className={classes.selector}
            variant="outlined"
            margin={'dense'}
            id="select-order"
            labelId="select-order-label"
          >
            <MenuItem className={classes.menuItem} value={'default'}>
              {t('Filters-Default')}
            </MenuItem>
            <MenuItem className={classes.menuItem} value={'apy'}>
              APY
            </MenuItem>
            <MenuItem className={classes.menuItem} value={'tvl'}>
              TVL
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
  const Filters = () => (
    <div
      className="bootstrap-column bootstrap-column--left"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <span
        style={{
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '21px',
        }}
      >
        Filter
      </span>
      <span
        style={{
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '12px',
          lineHeight: '15px',
          color: '#F07B7B',
          cursor: 'pointer',
          marginLeft: '24px',
        }}
        className="not-mobile"
        onClick={resetFilter}
      >
        Clear filters
      </span>
    </div>
  );

  return (
    <Grid container spacing={2} className={classes.container}>
      <div
        className="bootstrap-row"
        style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
        <div className="not-mobile">
          <Filters></Filters>
        </div>
        <div className="TextField-without-border-radius bootstrap-column bootstrap-column--right">
          <FormControl className={classes.selectorContainer}>
            <TextField
              id="input-with-icon-textfield"
              className={classes.selector}
              value={searchTerm}
              onChange={handleSearchTermChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              margin="dense"
              placeholder="Search"
              variant="outlined"
              style={{ borderRadius: '30px' }}
            />
          </FormControl>
        </div>
      </div>
      <Grid
        container
        spacing={2}
        className={`${classes.container} not-mobile`}
        style={{ margin: '0', padding: '0' }}
      >
        <Control></Control>
      </Grid>
      <Accordion style={{ width: '100%', background: 'transparent' }} className="only-mobile">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Filters></Filters>
        </AccordionSummary>
        <AccordionDetails>
          <span
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '15px',
              color: '#F07B7B',
              cursor: 'pointer',
              width: '100%',
            }}
            onClick={resetFilter}
          >
            Clear filters
          </span>
          <br></br>
          <Control></Control>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default memo(Filters);
