const styles = theme => ({
  container: {
    borderTop: '1px solid ' + theme.palette.background.border,
  },
  accordion: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  divider: {
    margin: '0 30px',
  },
});

export default styles;
