import React, { useEffect, useState, memo, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
// import HeaderTVLInfo from '../HeaderTokenInfo/HeaderTVLInfo';
import Avatar from '@material-ui/core/Avatar';
import { renderIcon } from '@download/blockies';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';
import WbSunny from '@material-ui/icons/WbSunny';
import NightsStay from '@material-ui/icons/NightsStay';
import Telegram from '@material-ui/icons/Telegram';
import Twitter from '@material-ui/icons/Twitter';
import { Icon } from '@iconify/react';
import { Fade, Tooltip } from '@material-ui/core';
import HeaderTokenPrice from '../HeaderTokenInfo/HeaderTokenPrice';
import HeaderLevelInfo from '../HeaderLevelInfo/HeaderLevelInfo';

import styles from './styles';
import { fetchUserLevel, fetchUserCredit } from '../../features/web3';
import BigNumber from 'bignumber.js';
import numbro from 'numbro';

const useStyles = makeStyles(styles);

const CreditTooltip = memo(({ remainingCredit, minusCredit }) => {
  const classes = useStyles();
  const minusCreditBN = new BigNumber(minusCredit);
  const remainingCreditBN = new BigNumber(remainingCredit);
  const formatConfig = {
    average: true,
    mantissa: 2,
    trimMantissa: true,
    spaceSeparated: true,
  };
  const fRemainingCredit = numbro(remainingCreditBN.toFixed(2)).format(formatConfig);
  const fMinusCredit = numbro(minusCreditBN.toFixed(2)).format(formatConfig);
  const credit = minusCreditBN.eq(0) ? fRemainingCredit : `${fRemainingCredit} (-${fMinusCredit})`;

  return (
    <table>
      <tbody>
        <tr key="remainingCredit">
          <th className={classes.label}>{'Point Balance:'}</th>
          <td className={classes.value}>{credit.toUpperCase()}</td>
        </tr>
      </tbody>
    </table>
  );
});

const ImgWithTooltip = memo(({ imgSrc, tooltip, ...passthrough }) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      TransitionComponent={Fade}
      title={tooltip}
      placement="bottom"
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      classes={{ tooltip: classes.tooltip }}
    >
      <HeaderLevelInfo imgSrc={imgSrc} {...passthrough} />
    </Tooltip>
  );
});

const Header = ({
  links,
  isNightMode,
  setNightMode,
  connected,
  address,
  connectWallet,
  disconnectWallet,
}) => {
  const { chain } = useParams();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [shortAddress, setShortAddress] = useState('');
  const [dataUrl, setDataUrl] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [userCredit, setUserCredit] = useState({ remainingCredit: '0', minusCredit: '0' });
  const [level, setLevel] = useState(0);
  // const [canvasRef, setCanvas] = useState(null);
  const canvasRef = createRef();
  // const canvasRef = useRef(document.createElement('canvas'));

  const navigateLink = linkName => {
    switch (linkName) {
      case 'twitter':
        window.open('https://twitter.com/belifinance', '_blank');
        break;
      case 'discord':
        window.open('https://discord.gg/njPjABbSnC', '_blank');
        break;
      case 'telegram':
        window.open('https://t.me/belifinanceofficial', '_blank');
        break;
      case 'gitbook':
        window.open('https://docs.beli.finance', '_blank');
        break;
      default:
        window.open('https://docs.beli.finance', '_blank');
        break;
    }
  };

  useEffect(() => {
    if (!connected) {
      return;
    }
    // const canvas = canvasRef.current;
    // const existingCanvas = document.getElementsByTagName('canvas')[0];
    // const canvas = existingCanvas ? createRef(existingCanvas) : createRef(document.createElement('canvas'));
    // setCanvas(canvas);
    if (canvasRef.current === null) {
      canvasRef.current = document.createElement('canvas');
    }
    const canvas = canvasRef.current;
    renderIcon({ seed: address.toLowerCase() }, canvas);
    const updatedDataUrl = canvas.toDataURL();
    if (updatedDataUrl !== dataUrl) {
      setDataUrl(updatedDataUrl);
    }
    if (address.length < 11) {
      setShortAddress(address);
    } else {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`);
    }

    const setLevelImage = (level, setImg) => {
      if (level === 1) {
        setImg(require('images/level/plus-one-small.png'));
      } else if (level === 2) {
        setImg(require('images/level/two-stars-small.png'));
      } else if (level === 3) {
        setImg(require('images/level/three-stars-small.png'));
      } else if (level === 4) {
        setImg(require('images/level/four-small.png'));
      }
    };

    // check level every minutes
    const job = setInterval(() => {
      const userLevel = fetchUserLevel(address);
      const credit = fetchUserCredit(address);
      if (userLevel !== level) {
        setLevel(userLevel);
        if (userLevel !== 0) {
          setLevelImage(userLevel, setImgSrc);
        }
      }
      setUserCredit(credit);
    }, 5_000);
    return () => clearInterval(job);
  }, [dataUrl, address, connected, setImgSrc, setLevel, level, setUserCredit, canvasRef]);

  return (
    <AppBar className={`${classes.appBar} ${classes.dark} logo-title`} position="relative">
      <Toolbar className={classes.container}>
        <Link
          to={`/${chain}`}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            alt="BELI"
            src={require(`images/beli_finance.png`)}
            height={'40px'}
            className={classes.logo}
          />
          <h3 className={classes.logoTitle}>Beli Finance</h3>
        </Link>

        <div className={classes.middleNav}>
          <Hidden smDown>{renderLink('vaults', 'Vaults', 'get-pocket fab', classes)}</Hidden>
        </div>

        <Hidden smDown implementation="css">
          <div className={classes.collapse}>
            <IconButton
              className={classes.iconButton}
              style={{ marginRight: '5px' }}
              onClick={() => navigateLink('discord')}
            >
              <Icon icon="ph:discord-logo-fill" />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              style={{ marginRight: '5px' }}
              onClick={() => navigateLink('twitter')}
            >
              <Twitter />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              style={{ marginRight: '5px' }}
              onClick={() => navigateLink('telegram')}
            >
              <Telegram />
            </IconButton>
            <IconButton className={classes.iconButton} onClick={() => navigateLink('gitbook')}>
              <Icon icon="simple-icons:gitbook" />
            </IconButton>
            <IconButton
              onClick={setNightMode}
              className={classes.iconButton}
              style={{ marginRight: '5px' }}
            >
              {isNightMode ? <WbSunny /> : <NightsStay />}
            </IconButton>
          </div>
        </Hidden>
        <HeaderTokenPrice />
        <Hidden smDown>
          {/* <HeaderTVLInfo /> */}
          <Button
            disableElevation
            className={classes.walletDisplay}
            onClick={connected ? disconnectWallet : connectWallet}
          >
            {connected ? (
              <>
                <canvas ref={canvasRef} style={{ display: 'none' }} />
                <Avatar
                  alt="address"
                  src={dataUrl}
                  style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '4px',
                  }}
                />
                {shortAddress}
              </>
            ) : (
              <span>{t('Vault-Wallet')}</span>
            )}
            {/* {
              !connected || level === 0 ? (<span></span>) : (
                <div>
                  <img
                    alt="level"
                    src={imgSrc}
                    style={{
                      width: '18px',
                      height: '18px',
                      marginLeft: '10px',
                      marginBottom: '3px'
                    }}
                  >
                  </img>
                </div>
              )
            } */}
            {!connected || level === 0 ? (
              <span></span>
            ) : (
              <ImgWithTooltip
                tooltip={
                  <CreditTooltip
                    remainingCredit={userCredit.remainingCredit}
                    minusCredit={userCredit.minusCredit}
                  ></CreditTooltip>
                }
                imgSrc={imgSrc}
              ></ImgWithTooltip>
            )}
          </Button>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            className={classes.iconButton}
            style={{ marginRight: '-13px' }}
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>

      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.themeButtonDrawer} style={{ display: 'inline-block' }}>
            <IconButton className={classes.iconButton} onClick={() => navigateLink('discord')}>
              <Icon icon="ph:discord-logo-fill" />
            </IconButton>
            <IconButton className={classes.iconButton} onClick={() => navigateLink('twitter')}>
              <Twitter />
            </IconButton>
            <IconButton className={classes.iconButton} onClick={() => navigateLink('telegram')}>
              <Telegram />
            </IconButton>
            <IconButton className={classes.iconButton} onClick={() => navigateLink('gitbook')}>
              <Icon icon="simple-icons:gitbook" />
            </IconButton>
            <IconButton
              onClick={setNightMode}
              className={`${classes.iconButton}`}
              style={{ marginRight: '5px' }}
            >
              {isNightMode ? <WbSunny /> : <NightsStay />}
            </IconButton>
          </div>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <div className={classes.collapse} style={{ flexDirection: 'column', paddingTop: '4rem' }}>
            <Button
              disableElevation
              className={classes.walletDisplay}
              style={{ width: '100%' }}
              onClick={connected ? disconnectWallet : connectWallet}
            >
              {connected ? (
                <>
                  <canvas ref={canvasRef} style={{ display: 'none' }} />
                  <Avatar
                    alt="address"
                    src={dataUrl}
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '4px',
                    }}
                  />
                  {shortAddress}
                </>
              ) : (
                <>{t('Vault-Wallet')}</>
              )}
              {!connected || level === 0 ? (
                <span></span>
              ) : (
                // <ImgWithTooltip
                //   tooltip={
                //     <CreditTooltip
                //       remainingCredit={userCredit.remainingCredit}
                //       minusCredit={userCredit.minusCredit}
                //     ></CreditTooltip>
                //   }
                //   imgSrc={imgSrc}
                // ></ImgWithTooltip>
                <img
                  alt="level"
                  src={imgSrc}
                  style={{
                    width: '18px',
                    height: '18px',
                    marginLeft: '10px',
                    marginBottom: '3px',
                  }}
                ></img>
              )}
            </Button>
            {!connected || level === 0 ? (
              <span></span>
            ) : (
              <CreditTooltip
                remainingCredit={userCredit.remainingCredit}
                minusCredit={userCredit.minusCredit}
              ></CreditTooltip>
            )}
            <LinkSidebar name="vaults" label={'Vaults'} icon="get-pocket fab" classes={classes} />
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

const renderLink = (name, label, icon, classes, paddingLeft) => {
  return (
    <a
      href={getLinkUrl(name)}
      rel="noopener noreferrer"
      className={classes.link}
      style={{ paddingLeft: paddingLeft ?? '40px' }}
    >
      <i className={`fas fa-${icon} ${classes.icon}`} />
      <span>{label}</span>
    </a>
  );
};

const LinkSidebar = ({ name, label, icon, classes }) => (
  <div style={{ width: '100%', paddingTop: '20px', textAlign: 'center' }}>
    {renderLink(name, label, icon, classes, '0px')}
  </div>
);

const getLinkUrl = name => {
  return `/`;
};

export default Header;
