import axios from 'axios';

/**
 * @type {{
 *  cache: Map<'info', { level: number; remainingCredit: string; minusCredit: string; }>;
 *  lastUpdated: Date | null;
 *  userAddress: string | null;
 * }}
 */
const userCache = {
  cache: new Map(),
  lastUpdated: null,
  userAddress: null,
};

const CACHE_TIMEOUT_MS = 1 * 60 * 1000;

function _getUserLevel(address) {
  if (typeof userCache.userAddress !== 'string') {
    // handle initial state
    return 0;
  }
  if (userCache.userAddress.toLowerCase() === address.toLowerCase()) {
    return userCache.cache.get('info').level;
  } else {
    // handle wallet reconnect with different account
    return 0;
  }
}

function _getUserCredit(address) {
  if (typeof userCache.userAddress !== 'string') {
    // handle initial state
    return { remainingCredit: '0', minusCredit: '0' };
  }
  if (userCache.userAddress.toLowerCase() === address.toLowerCase()) {
    return {
      remainingCredit: userCache.cache.get('info').remainingCredit,
      minusCredit: userCache.cache.get('info').minusCredit,
    };
  } else {
    // handle wallet reconnect with different account
    return { remainingCredit: '0', minusCredit: '0' };
  }
}

function isCached(address) {
  if (userCache.userAddress === null) {
    return false;
  }
  if (userCache.userAddress.toLowerCase() !== address.toLowerCase()) {
    return false;
  } else if (
    userCache.lastUpdated !== null &&
    Date.now() < userCache.lastUpdated.getTime() + CACHE_TIMEOUT_MS
  ) {
    return true;
  }
  return false;
}

/**
 * @returns {Promise<{ level: number }>}
 */
async function _getUserInfo(address) {
  const url = `https://api.beli.finance/users/info?address=${address}&_=${Date.now()}`;
  const info = await axios.get(url);
  return info.data;
}

async function initializeInfoCache(address) {
  userCache.lastUpdated = new Date();
  const info = await _getUserInfo(address);
  userCache.cache.set('info', info);
  userCache.userAddress = address;
}

async function maybeUpdateCache(address) {
  if (isCached(address)) {
    return;
  }
  await initializeInfoCache(address);
}

export const fetchUserLevel = address => {
  if (typeof address !== 'string' || address.length === 0) {
    console.error('invalid address');
    return 0;
  }
  maybeUpdateCache(address);
  return _getUserLevel(address);
};

export const fetchUserCredit = address => {
  if (typeof address !== 'string' || address.length === 0) {
    console.error('invalid address');
    return 0;
  }
  maybeUpdateCache(address);
  return _getUserCredit(address);
};
