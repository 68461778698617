import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VAULT_FETCH_UNLOCK_LOCKER_BEGIN,
  VAULT_FETCH_UNLOCK_LOCKER_SUCCESS,
  VAULT_FETCH_UNLOCK_LOCKER_FAILURE,
} from './constants';
import { unlockLocker } from '../../web3';

function fetchUnlockLocker({ web3, address }) {
  return dispatch => {
    dispatch({
      type: VAULT_FETCH_UNLOCK_LOCKER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      unlockLocker({ web3, address, dispatch })
        .then((data) => {
          dispatch({
            type: VAULT_FETCH_UNLOCK_LOCKER_SUCCESS,
            data,
          });
          resolve();
        }).catch((error) => {
          dispatch({
            type: VAULT_FETCH_UNLOCK_LOCKER_FAILURE,
          });
          reject(error.message || error);
        })
    });

    return promise;
  }
}

export function useFetchLockerUnlock() {
  const dispatch = useDispatch();

  const { fetchUnlockLockerPending } = useSelector((state) => ({
    fetchUnlockLockerPending: state.vault.fetchUnlockLockerPending,
  }));

  const boundAction = useCallback(data => {
    return dispatch(fetchUnlockLocker(data));
  }, [dispatch]);

  return {
    fetchUnlockLocker: boundAction,
    fetchUnlockLockerPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_UNLOCK_LOCKER_BEGIN:
      return {
        ...state,
        fetchUnlockLockerPending: true,
      };

    case VAULT_FETCH_UNLOCK_LOCKER_SUCCESS:
      return {
        ...state,
        fetchUnlockLockerPending: false,
      };

    case VAULT_FETCH_UNLOCK_LOCKER_FAILURE:
      return {
        ...state,
        fetchUnlockLockerPending: false,
      };

    default:
      return state;
  }
}
