import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  VAULT_FETCH_REWARDS_BEGIN,
  VAULT_FETCH_REWARDS_SUCCESS,
  VAULT_FETCH_REWARDS_FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import { chefABI } from 'features/configure';
import { getNetworkMulticall } from 'features/helpers/getNetworkData';

function fetchRewards({ address, web3, pools }) {
  return dispatch => {
    if (!(address && web3)) return;

    dispatch({
      type: VAULT_FETCH_REWARDS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const multicall = new MultiCall(web3, getNetworkMulticall());

      const pendingCalls = [];

      pools.forEach((pool) => {
        const { pid, id } = pool;
        const chefContract = new web3.eth.Contract(chefABI, pool.earnContractAddress);
        pendingCalls.push({
          beli: chefContract.methods.pendingBELI(pid, address), // from emission rate
          feeReceiver: chefContract.methods.pendingFeeReceiverBeliReward(pid, address), // Fee Receiver
          feeReceiverLP: chefContract.methods.pendingFeeReceiverBeliLpReward(pid, address), // Fee Receiver (LP)
          convertedEarn: chefContract.methods.pendingConvertedEarn(pid, address), // converteard earn -> BELI
          poolId: id,
        });
      });

      multicall
        .all([pendingCalls])
        .then(([results]) => {
          const pendingRewards = {};
          results.forEach((res) => {
            pendingRewards[res.poolId] = {
              pendingBeli: res.beli,
              pendingFeeReceiver: res.feeReceiver,
              pendingFeeReceiverLP: res.feeReceiverLP,
              pendingConvertedEarn: res.convertedEarn,
            };
          });
          dispatch({
            type: VAULT_FETCH_REWARDS_SUCCESS,
            data: pendingRewards,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_REWARDS_FAILURE,
          });
          return reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchRewards() {
  const dispatch = useDispatch();

  const { rewards, fetchRewardsPending, fetchRewardsDone } = useSelector(
    state => ({
      rewards: state.vault.rewards,
      fetchRewardsDone: state.vault.fetchRewardsDone,
      fetchRewardsPending: state.vault.fetchRewardsPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchRewards(data));
    },
    [dispatch]
  );

  return {
    rewards,
    fetchRewards: boundAction,
    fetchRewardsDone,
    fetchRewardsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_REWARDS_BEGIN:
      return {
        ...state,
        fetchRewardsPending: true,
      };

    case VAULT_FETCH_REWARDS_SUCCESS:
      const newAndUpdatedRewards = {};
      Object.entries(action.data).forEach(([symbol, reward]) => {
        newAndUpdatedRewards[symbol] = {
          ...state.rewards[symbol],
          ...reward,
        };
      });

      return {
        ...state,
        rewards: {
          ...state.rewards,
          ...newAndUpdatedRewards,
        },
        fetchRewardsDone: true,
        fetchRewardsPending: false,
      };

    case VAULT_FETCH_REWARDS_FAILURE:
      return {
        ...state,
        fetchRewardsPending: false,
      };

    default:
      return state;
  }
}
