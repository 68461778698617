import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { MultiCall } from 'eth-multicall';
import {
  VAULT_FETCH_HARVEST_FEE_BEGIN,
  VAULT_FETCH_HARVEST_FEE_FAILURE,
  VAULT_FETCH_HARVEST_FEE_SUCCESS,
} from './constants';
import { chefABI } from 'features/configure';
import { getNetworkMulticall } from 'features/helpers/getNetworkData';

/**
 * for fetching all vaults in single call, recommended only for initialization or handling account change
 * for single vault use fetchHarvestFee instead
 */
function fetchBulkHarvestFee({ web3, address, pools }) {
  return dispatch => {
    if (!web3 || !address) return;

    dispatch({
      type: VAULT_FETCH_HARVEST_FEE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const multicall = new MultiCall(web3, getNetworkMulticall());
      const calls = pools.map(pool => {
        const contract = new web3.eth.Contract(chefABI, pool.earnContractAddress);
        return { [pool.id]: contract.methods.userInfo(pool.pid, address) };
      });

      multicall
        .all([calls])
        .then(([results]) => {
          const harvestFee = {};
          results.forEach(r => {
            const entries = Object.entries(r);
            const [[k, v]] = entries;
            const harvestFeeUntil = typeof v !== 'undefined' ? v['6'] : '0';
            harvestFee[k] = parseInt(harvestFeeUntil, 10);
          });
          dispatch({
            type: VAULT_FETCH_HARVEST_FEE_SUCCESS,
            data: harvestFee,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_HARVEST_FEE_FAILURE,
          });
          return reject(error.message || error);
        });
    });
    return promise;
  };
}

function fetchHarvestFee({ web3, address, pool }) {
  return dispatch => {
    if (!web3 || !address) return;

    dispatch({
      type: VAULT_FETCH_HARVEST_FEE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const contract = new web3.eth.Contract(chefABI, pool.earnContractAddress);
      contract.methods
        .harvestFeeUntil(pool.pid)
        .call({ from: address })
        .then(result => {
          const data = { [pool.id]: parseInt(result, 10) };
          dispatch({ type: VAULT_FETCH_HARVEST_FEE_SUCCESS, data });
          resolve();
        })
        .catch(error => {
          dispatch({ type: VAULT_FETCH_HARVEST_FEE_FAILURE });
          return reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchHarvestFee() {
  const dispatch = useDispatch();

  const { harvestFeeUntil, fetchHarvestFeePending, fetchHarvestFeeDone } = useSelector(
    state => ({
      harvestFeeUntil: state.vault.harvestFeeUntil,
      fetchHarvestFeePending: state.vault.fetchHarvestFeePending,
      fetchHarvestFeeDone: state.vault.fetchHarvestFeeDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchHarvestFee(data));
    },
    [dispatch]
  );

  const fetchBulk = useCallback(
    data => {
      return dispatch(fetchBulkHarvestFee(data));
    },
    [dispatch]
  );

  return {
    harvestFeeUntil,
    fetchHarvestFee: boundAction,
    fetchBulkHarvestFee: fetchBulk,
    fetchHarvestFeePending,
    fetchHarvestFeeDone,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_HARVEST_FEE_BEGIN:
      return {
        ...state,
        fetchHarvestFeePending: true,
      };

    case VAULT_FETCH_HARVEST_FEE_SUCCESS:
      const updatedHarvestFeeUntil = {
        ...action.data,
      };
      return {
        ...state,
        harvestFeeUntil: {
          ...state.harvestFeeUntil,
          ...updatedHarvestFeeUntil,
        },
        fetchHarvestFeeDone: true,
        fetchHarvestFeePending: false,
      };

    case VAULT_FETCH_HARVEST_FEE_FAILURE:
      return {
        ...state,
        fetchHarvestFeePending: false,
      };

    default:
      return state;
  }
}
