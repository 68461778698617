import { useState, useEffect } from 'react';
import { byDecimals } from '../../helpers/bignumber';
import { formatTvlNumber } from '../../helpers/format';
import { fetchPrice } from '../../../features/web3';
import { useFetchVaultsData, useFetchApys } from '../../../features/vault/redux/hooks';
// import { formatApy } from '../../helpers/format';

const usePoolsTvl = pools => {
  const [poolsTvl, setPoolsTvl] = useState(0);

  useEffect(() => {
    let globalTvl = 0;

    pools.filter(isUniquePool).forEach(({ tvl, oraclePrice }) => {
      globalTvl += tvl * oraclePrice;
    });

    setPoolsTvl(globalTvl);
  }, [pools]);

  return { poolsTvl };
};

const usePriceInfo = () => {
  const [priceInfo, setPriceInfo] = useState(0);

  useEffect(() => {
    const price = fetchPrice({ id: 'BELI' });
    setPriceInfo(price ?? 0);
  }, [priceInfo, setPriceInfo]);

  return { priceInfo };
};

const yearlyToDaily = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 365) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};

const useUserTvl = (pools, tokens) => {
  const [userTvl, setUserTvl] = useState(0);
  const [userAssets, setUserAssets] = useState(0);
  const [averageApy, setAverageApy] = useState(0);
  const [dailyApy, setDailyApy] = useState(0);
  const { fetchVaultsDataDone } = useFetchVaultsData();
  const { apys } = useFetchApys();
  useEffect(() => {
    let userTvl = 0;
    let userAssets = 0;
    let averageApy = 0;
    let dailyApy = 0;
    let apyCalculate = [];
    pools.filter(isUniquePool).forEach(pool => {
      const deposited = byDecimals(pool.staked, pool.tokenDecimals);
      const depositedUsd =
        deposited > 0 && fetchVaultsDataDone
          ? formatTvlNumber(deposited, pool.oraclePrice, false)
          : 0;
      if (deposited > 0) {
        userAssets++;
        const apy = apys[pool.id];
        const values = {};

        // values.totalApy = apy.totalApy;
        if (apy) {
          values.totalApy = apy.totalApy;
          values.vaultTradeApy = apy.vaultTradeApy ? apy.vaultTradeApy : apy.vaultApy;
          values.beliApy = apy.beliApy;
          values.stakingApy = apy.stakingApy || 0;
          values.compoundingsPerYear = apy.compoundingsPerYear;
          values.beliCompoundTimes = apy.beliCompoundTimes
            ? apy.beliCompoundTimes
            : apy.compoundingsPerYear;
        } else {
          values.totalApy = 0;
          values.vaultTradeApy = 0;
          values.beliApy = 0;
          values.stakingApy = 0;
          values.compoundingsPerYear = 0;
          values.beliCompoundTimes = 0;
        }

        if (apy && 'vaultApr' in apy && apy.vaultApr) {
          values.vaultApr = apy.vaultApr;
          values.vaultDaily = apy.vaultApr / 365;
        }

        if (apy && 'beliApr' in apy && apy.beliApr) {
          values.beliApr = apy.beliApr;
          values.beliDaily = apy.beliApr / 365;
        }

        if (apy && 'tradingApr' in apy && apy.tradingApr) {
          values.tradingApr = apy.tradingApr;
          values.tradingDaily = apy.tradingApr / 365;
        }

        if (apy && 'stakingApr' in apy && apy.stakingApr) {
          values.stakingApr = apy.stakingApr;
          values.stakingDaily = apy.stakingApr / 365;
        }

        if (apy && ('vaultAprDaily' in values || 'tradingAprDaily' in values)) {
          values.totalDaily = (values.vaultDaily || 0) + (values.tradingDaily || 0);
        } else {
          // values.totalDaily = yearlyToDaily(values.totalApy);
          values.totalDaily =
            yearlyToDaily(values.beliApy, values.beliCompoundTimes) +
            yearlyToDaily(values.vaultTradeApy, values.compoundingsPerYear) +
            yearlyToDaily(values.stakingApy, values.beliCompoundTimes);
        }

        // const formatted = Object.fromEntries(
        //   Object.entries(values).map(([key, value]) => {
        //     const formattedValue = key.toLowerCase().includes('daily')
        //       ? formatApy(value, 4)
        //       : formatApy(value);
        //     return [key, formattedValue];
        //   })
        // );

        apyCalculate.push({
          depositedUsd: depositedUsd,
          totalApy: values.totalApy,
          totalDaily: values.totalDaily,
        });
      }
      userTvl += depositedUsd;
    });
    setUserTvl(userTvl);
    setUserAssets(userAssets);
    apyCalculate.forEach(item => {
      averageApy += (item.depositedUsd / userTvl) * item.totalApy;
      dailyApy += (item.depositedUsd / userTvl) * item.totalDaily;
    });
    setAverageApy(averageApy);
    setDailyApy(dailyApy);
  }, [apys, fetchVaultsDataDone, pools, tokens]);

  return { userTvl, userAssets, averageApy, dailyApy };
};

const isUniquePool = (pool, index, pools) => {
  const earnedTokenAddress = pool.earnedTokenAddress;
  return pools.findIndex(p => p.earnedTokenAddress === earnedTokenAddress) === index;
};

export { usePoolsTvl, useUserTvl, isUniquePool, usePriceInfo };
