import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useUserTvl } from '../../features/vault/hooks/usePoolsTvl';
import styles from './styles';
import { useConnectWallet } from '../../features/home/redux/connectWallet';
import {
  useFetchBalances,
  useFetchVaultsData,
  useFetchLocker,
  useFetchRewards,
  useFetchLockerUnlock,
} from '../../features/vault/redux/hooks';
import { formatGlobalTvl } from 'features/helpers/format';
import TVLLoader from '../../features/vault/components/Pools/TVLLoader/TVLLoader';
import BigNumber from 'bignumber.js';
import { byDecimals } from 'features/helpers/bignumber';
import { fetchPrice } from 'features/web3';
import { formatTvl, formatFullApy } from 'features/helpers/format';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(styles);

const AccountInfo = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { web3, address } = useConnectWallet();
  const {
    pools,
    // fetchVaultsData,
    // fetchVaultsDataPending,
    fetchVaultsDataDone,
  } = useFetchVaultsData();
  const {
    tokens,
    // fetchBalances,
    // fetchBalancesPending,
    fetchBalancesDone,
  } = useFetchBalances();
  const {
    userLocker,
    globalLocker,
    fetchUserLocker,
    fetchGlobalLocker,
    // fetchUserLockerPending,
    fetchUserLockerDone,
    // fetchGlobalLockerPending,
    // fetchGlobalLockerDone,
  } = useFetchLocker();
  const { enqueueSnackbar } = useSnackbar();
  const {
    fetchUnlockLocker,
    // fetchUnlockLockerPending,
  } = useFetchLockerUnlock();
  const {
    userTvl,
    userAssets,
    averageApy,
    // dailyApy,
  } = useUserTvl(pools, tokens);
  const { rewards } = useFetchRewards();
  const { locked: userLocked, claimed, canUnlockAmount } = userLocker;
  const {
    // totalLocked,
    startReleaseBlock,
    endReleaseBlock,
  } = globalLocker;
  const [currentBlock, setCurrentBlock] = useState(0);
  useEffect(() => {
    fetchGlobalLocker({ web3 });
    if (address) {
      fetchUserLocker({ address, web3 });
    }
    web3.eth.getBlockNumber().then(block => setCurrentBlock(block));
  }, [fetchGlobalLocker, fetchUserLocker, web3, address]);

  const handleClaimLocker = () => {
    fetchUnlockLocker({
      address,
      web3,
    })
      .then(() => {
        enqueueSnackbar(t('Locker-ClaimSuccess'), { variant: 'success' });
        fetchUserLocker({ web3, address });
      })
      .catch(error => enqueueSnackbar(t('Locker-ClaimError', { error }), { variant: 'error' }));
  };

  const results = Object.values(rewards).reduce((result, reward) => {
    const pendingBeli = new BigNumber(reward.pendingBeli);
    const pendingFeeReceiver = new BigNumber(reward.pendingFeeReceiver);
    const pendingFeeReceiverLP = new BigNumber(reward.pendingFeeReceiverLP);
    const pendingConvertedEarn = new BigNumber(reward.pendingConvertedEarn);

    return pendingBeli
      .plus(pendingFeeReceiver)
      .plus(pendingFeeReceiverLP)
      .plus(pendingConvertedEarn)
      .plus(result);
  }, 0);
  const price = fetchPrice({ id: 'BELI' });
  const releasePercentage =
    (1 - (endReleaseBlock - currentBlock) / (endReleaseBlock - startReleaseBlock)) * 100;
  const percentage = releasePercentage > 0 ? releasePercentage : 0;
  const oneDayBlock = 20 * 60 * 24;
  const days = Math.round((startReleaseBlock - currentBlock) / oneDayBlock);
  const hashNumber = startReleaseBlock;
  const RemainingTime =
    ((startReleaseBlock - currentBlock) * Number(3)) / 60 / 60 / 24 > 1
      ? `${Math.round(((startReleaseBlock - currentBlock) * Number(3)) / 60 / 60 / 24)} days`
      : `${Math.floor(
          Math.round(((startReleaseBlock - currentBlock) * Number(3)) / 60) / 60
        )} hours ${Math.round(((startReleaseBlock - currentBlock) * Number(3)) / 60) % 60} minutes`;

  const UnlockRemainingTime =
    ((endReleaseBlock - currentBlock) * Number(3)) / 60 / 60 / 24 > 1
      ? `${Math.round(((endReleaseBlock - currentBlock) * Number(3)) / 60 / 60 / 24)} days`
      : `${Math.floor(
          Math.round(((endReleaseBlock - currentBlock) * Number(3)) / 60) / 60
        )} hours ${Math.round(((endReleaseBlock - currentBlock) * Number(3)) / 60) % 60} minutes`;
  // console.log('unlock remain', UnlockRemainingTime);
  return (
    <Grid
      container
      spacing={2}
      style={{
        backgroundFilter: 'blur(4px)',
        borderRadius: '30px',
        marginTop: '16px',
        padding: '8px',
      }}
      className={classes.overlayBackground}
    >
      <Grid item xs={12}>
        <span style={{ fontWeight: '500', fontSize: '20px', lineHeight: '21px' }}>
          Your account
        </span>
      </Grid>
      <Grid item xs={12} md={4}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(255, 255, 255, 0.16)',
            boxSizing: 'border-box',
            borderRadius: '20px',
            padding: '16px',
            minHeight: '120px',
          }}
          className={classes.accountBoxBackground}
        >
          <span style={{ fontWeight: 'normal', fontSize: '12px', lineHeight: '18px' }}>
            Total deposit
          </span>
          <span style={{ fontWeight: '600', fontSize: '20px', lineHeight: '30px' }}>
            {fetchVaultsDataDone && fetchBalancesDone ? formatGlobalTvl(userTvl) : <TVLLoader />}
          </span>
          <span
            style={{
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#777777',
            }}
          >
            {userAssets} assets
          </span>
          {/* <a
            href="javascript:void(0)"
            style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px' }}
          >
            View Breakdown
          </a> */}
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(255, 255, 255, 0.16)',
            boxSizing: 'border-box',
            borderRadius: '20px',
            padding: '16px',
            minHeight: '120px',
          }}
          className={classes.accountBoxBackground}
        >
          <span style={{ fontWeight: 'normal', fontSize: '12px', lineHeight: '18px' }}>
            Average APY
          </span>
          <span style={{ fontWeight: '600', fontSize: '20px', lineHeight: '30px' }}>
            {formatFullApy(averageApy, 0)}
          </span>
          {/* <a
            href="javascript:void(0)"
            style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px' }}
          >
            Projection
          </a> */}
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(255, 255, 255, 0.16)',
            boxSizing: 'border-box',
            borderRadius: '20px',
            padding: '16px',
            minHeight: '120px',
          }}
          className={classes.accountBoxBackground}
        >
          <span style={{ fontWeight: 'normal', fontSize: '12px', lineHeight: '18px' }}>
            BELI rewards
          </span>
          <span style={{ fontWeight: '600', fontSize: '20px', lineHeight: '30px' }}>
            {byDecimals(results, 18).toFormat(8)}
          </span>
          <span
            style={{
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#777777',
            }}
          >
            {formatTvl(byDecimals(results, 18), price)}
          </span>
          {/* <a
            href="javascript:void(0)"
            style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px' }}
          >
            Harvest all(1)
          </a> */}
        </div>
      </Grid>
      {/* <Grid item xs={12} md={4}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(255, 255, 255, 0.16)',
            boxSizing: 'border-box',
            borderRadius: '20px',
            padding: '16px',
            minHeight: '120px',
          }}
          className={classes.accountBoxBackground}
        >
          <span style={{ fontWeight: 'normal', fontSize: '12px', lineHeight: '18px' }}>
            WMATIC Rewards
          </span>
          <span style={{ fontWeight: '600', fontSize: '20px', lineHeight: '30px' }}>0.000</span>
          <span
            style={{
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#777777',
            }}
          >
            $10.30
          </span>
        </div>
      </Grid> */}
      <Grid item xs={12} style={{ marginTop: '10px' }}>
        <span style={{ fontWeight: '500', fontSize: '20px', lineHeight: '21px' }}>
          Your locker stats
        </span>
      </Grid>
      <Grid item xs={12} md={4}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(255, 255, 255, 0.16)',
            boxSizing: 'border-box',
            borderRadius: '20px',
            padding: '16px',
            minHeight: '120px',
          }}
          className={classes.accountBoxBackground}
        >
          <span style={{ fontWeight: '600', fontSize: '20px', lineHeight: '30px' }}>
            {byDecimals(canUnlockAmount, 18).toFormat(8)}
          </span>
          <span
            style={{
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#777777',
            }}
          >
            Available for claim
          </span>
          {/*
           * @TODO: change the following claim now to button and set it so that it is disabled
           * when startReleaseBlock < currentBlock
           */}
          <a
            href="javascript:void(0)"
            style={{ fontWeight: '600', fontSize: '12px', lineHeight: '18px' }}
            onClick={handleClaimLocker}
          >
            Claim now
          </a>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(255, 255, 255, 0.16)',
            boxSizing: 'border-box',
            borderRadius: '20px',
            padding: '16px',
            minHeight: '120px',
          }}
          className={classes.accountBoxBackground}
        >
          <span style={{ fontWeight: '600', fontSize: '20px', lineHeight: '30px' }}>
            {fetchUserLockerDone ? byDecimals(claimed, 18).toFormat(8) : '???'}
          </span>
          <span
            style={{
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#777777',
            }}
          >
            Claimed
          </span>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(255, 255, 255, 0.16)',
            boxSizing: 'border-box',
            borderRadius: '20px',
            padding: '16px',
            minHeight: '120px',
          }}
          className={classes.accountBoxBackground}
        >
          <span style={{ fontWeight: '600', fontSize: '20px', lineHeight: '30px' }}>
            {byDecimals(userLocked, 18).toFormat(8)}
          </span>
          <span
            style={{
              fontWeight: 'normal',
              fontSize: '12px',
              lineHeight: '18px',
              color: '#777777',
            }}
          >
            BELI locked in locker
          </span>
        </div>
      </Grid>
      {/* {currentBlock <= endReleaseBlock && (
        <Grid item xs={12} md={12}>
          <LockerCountdown
            startReleaseBlock={startReleaseBlock}
            endBlock={endReleaseBlock}
            block={currentBlock}
          ></LockerCountdown>
        </Grid>
      )} */}
      <Grid item xs={12}>
        <ProgressBar
          percentage={percentage}
          hashNumber={hashNumber}
          days={days}
          remainingTime={RemainingTime}
          classes={classes}
          startReleaseBlock={startReleaseBlock}
          endReleaseBlock={endReleaseBlock}
          currentBlock={currentBlock}
          unlockRemainingTime={UnlockRemainingTime}
        ></ProgressBar>
      </Grid>
    </Grid>
  );
};

const ProgressBar = ({
  percentage,
  classes,
  days,
  startReleaseBlock,
  endReleaseBlock,
  currentBlock,
  remainingTime,
  unlockRemainingTime,
}) => (
  <>
    <div style={{ display: 'flex', marginBottom: '5px' }} className={classes.background}>
      <div style={{ width: percentage + '%' }} className={classes.backgroundPassed}></div>
    </div>
    {currentBlock < startReleaseBlock && (
      <div className={classes.mainColor}>
        Smooth unlock starts in {remainingTime} (#{startReleaseBlock})
      </div>
    )}
    {currentBlock < endReleaseBlock && currentBlock >= startReleaseBlock && (
      <div className={classes.mainColor}>
        Smooth release period end in {unlockRemainingTime} (#{endReleaseBlock})
      </div>
    )}
  </>
);

export default AccountInfo;
