import React, { memo } from 'react';
// import { useTranslation } from 'react-i18next';
// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import styles from './styles';

// const useStyles = makeStyles(styles);

const Footer = () => {
  // const classes = useStyles();
  // const { t } = useTranslation();

  return (
    <>
      <Grid container style={{ marginTop: '30px' }} spacing={2}>
        <Grid item xs={12}>
          <span
            style={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '11px',
              lineHeight: '15px',
              paddingBottom: '20px',
            }}
          >
            DISCLAIMER: Using Smart Contracts, Tokens, and Crypto is always a risk. Do Own Your
            Research (DYOR) before investing.
          </span>
          <Grid
            style={{ textAlign: 'center', paddingBottom: '20px', marginTop: '24px' }}
            item
            xs={12}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '13px',
                lineHeight: '18px',
                color: '#707070',
              }}
            >
              © 2021 Beli Finance.
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(Footer);
