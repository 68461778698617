import { primaryColor } from 'assets/jss/material-kit-pro-react.js';

const styles = theme => ({
  sliderDetailContainer: {
    padding: '20px 20px',
  },
  dialogbg: {
    backgroundColor: theme.palette.background.dialogCustom
  },
  dialogtext: {
    color: theme.palette.text.dialogCustom
  },
  dialogbuttontext: {
    color: theme.palette.text.dialogCustomButton
  },
  showDetailLeft: {
    float: 'left',
    marginBottom: '10px',
    fontSize: '1rem',
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    fontWeight: '500',
  },
  harvestInfo: {
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  hightLightFee: {
    color: '#3385FF',
  },
  showDetailBottom: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& + &': {
      marginLeft: '5px',
    },
  },
  showResponsiveButtonCon: {
    width: '160px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '80px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100px',
    },
  },
  showDetailButton: {
    margin: '12px 5px',
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '5px',
    width: '160px',
  },
  showDetailButtonOutlined: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    color: primaryColor[0],
    '&:hover': {
      '& .MuiButton-label': {
        color: 'white',
      },
    },
    '& .MuiTouchRipple-root span': {
      backgroundColor: primaryColor[0],
    },
  },
  showDetailButtonContained: {
    backgroundColor: primaryColor[0],
    '& .MuiButton-label': {
      color: 'white',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    lineHeight: '18px',
    fontStyle: 'normal',
    letterSpacing: 0,
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
    lineHeight: '16px',
    letterSpacing: 0,
  },
  url: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: 0,
    '&:hover,&:focus': {
      color: theme.palette.text.secondary,
    },
  },
});

export default styles;
