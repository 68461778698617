import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { BigNumber } from 'bignumber.js';
import {
  VAULT_FETCH_GLOBAL_LOCKER_BEGIN,
  VAULT_FETCH_GLOBAL_LOCKER_SUCCESS,
  VAULT_FETCH_GLOBAL_LOCKER_FAILURE,
  VAULT_FETCH_USER_LOCKER_BEGIN,
  VAULT_FETCH_USER_LOCKER_SUCCESS,
  VAULT_FETCH_USER_LOCKER_FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import { lockerABI } from 'features/configure';
import { getNetworkMulticall } from 'features/helpers/getNetworkData';

const LOCKER_ADDRESS = '0x337e095f9ca2d13e70d95847ed0a625b0413bd89';

function fetchGlobalLockerData({ web3 }) {
  return dispatch => {
    if (!web3) return;

    dispatch({
      type: VAULT_FETCH_GLOBAL_LOCKER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const multicall = new MultiCall(web3, getNetworkMulticall());
      const lockerContract = new web3.eth.Contract(lockerABI, LOCKER_ADDRESS);
      const calls = [
        {
          totalLocked: lockerContract.methods.totalLock(),
          startReleaseBlock: lockerContract.methods.startReleaseBlock(),
          endReleaseBlock: lockerContract.methods.endReleaseBlock(),
        },
      ];

      multicall
        .all([calls])
        .then(([result]) => {
          const locker = result[0];
          dispatch({
            type: VAULT_FETCH_GLOBAL_LOCKER_SUCCESS,
            data: locker,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_GLOBAL_LOCKER_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

function fetchUserLockerData({ web3, address }) {
  return dispatch => {
    if (!(address && web3)) return;

    dispatch({
      type: VAULT_FETCH_USER_LOCKER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const multicall = new MultiCall(web3, getNetworkMulticall());
      const lockerContract = new web3.eth.Contract(lockerABI, LOCKER_ADDRESS);
      const calls = [
        {
          lockOf: lockerContract.methods.lockOf(address),
          released: lockerContract.methods.released(address),
          canUnlockAmount: lockerContract.methods.canUnlockAmount(address),
        },
      ];

      multicall
        .all([calls])
        .then(([result]) => {
          const { lockOf, released, canUnlockAmount } = result[0];
          const userLocker = {
            locked: new BigNumber(lockOf).minus(released).toNumber(),
            claimed: new BigNumber(released).toNumber(),
            canUnlockAmount: new BigNumber(canUnlockAmount).toNumber(),
          };
          dispatch({
            type: VAULT_FETCH_USER_LOCKER_SUCCESS,
            data: userLocker,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_USER_LOCKER_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchLocker() {
  const dispatch = useDispatch();

  const {
    globalLocker,
    fetchGlobalLockerPending,
    fetchGlobalLockerDone,
    userLocker,
    fetchUserLockerPending,
    fetchUserLockerDone,
  } = useSelector(
    state => ({
      globalLocker: state.vault.globalLocker,
      fetchGlobalLockerPending: state.vault.fetchGlobalLockerPending,
      fetchGlocbalLockerDone: state.vault.fetchGlobalLockerDone,
      userLocker: state.vault.userLocker,
      fetchUserLockerData: state.vault.fetchUserLockerData,
      fetchUserLockerDone: state.vault.fetchUserLockerDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchGlobalLockerData(data));
    },
    [dispatch]
  );

  const boundUserLockerAction = useCallback(
    data => {
      return dispatch(fetchUserLockerData(data));
    },
    [dispatch]
  );

  return {
    globalLocker,
    fetchGlobalLocker: boundAction,
    fetchGlobalLockerDone,
    fetchGlobalLockerPending,
    userLocker,
    fetchUserLocker: boundUserLockerAction,
    fetchUserLockerDone,
    fetchUserLockerPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_GLOBAL_LOCKER_BEGIN:
      return {
        ...state,
        fetchGlobalLockerPending: true,
      };

    case VAULT_FETCH_GLOBAL_LOCKER_SUCCESS:
      return {
        ...state,
        globalLocker: action.data,
        fetchGlobalLockerDone: true,
        fetchGlobalLockerPending: false,
      };

    case VAULT_FETCH_GLOBAL_LOCKER_FAILURE:
      return {
        ...state,
        fetchGlobalLockerPending: false,
      };

    case VAULT_FETCH_USER_LOCKER_BEGIN:
      return {
        ...state,
        fetchUserLockerPending: true,
      };

    case VAULT_FETCH_USER_LOCKER_SUCCESS:
      return {
        ...state,
        userLocker: action.data,
        fetchUserLockerDone: true,
        fetchUserLockerPending: false,
      };

    case VAULT_FETCH_USER_LOCKER_FAILURE:
      return {
        ...state,
        fetchUserLockerPending: false,
      };

    default:
      return state;
  }
}
