import React, { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
// import { makeStyles } from '@material-ui/core/styles';
import { useConnectWallet } from 'features/home/redux/hooks';
import {
  useFetchBalances,
  useFetchVaultsData,
  useFetchApys,
  useFetchRewards,
  useFetchHarvestFee,
} from '../../redux/hooks';
import VisiblePools from '../VisiblePools/VisiblePools';
// import styles from './styles';
// import { usePoolsTvl, useUserTvl } from '../../hooks/usePoolsTvl';
// import { getNetworkFriendlyName } from '../../../helpers/getNetworkData';

const FETCH_INTERVAL_MS = 15 * 1000;

// const useStyles = makeStyles(styles);

export default function Pools() {
  // const { t } = useTranslation();
  const { web3, address } = useConnectWallet();
  const { pools, fetchVaultsData, fetchStakeData, fetchVaultsDataPending, fetchVaultsDataDone } =
    useFetchVaultsData();
  const { tokens, fetchBalances, fetchBalancesPending, fetchBalancesDone } = useFetchBalances();
  const { apys, fetchApys, fetchApysDone } = useFetchApys();
  const { rewards, fetchRewards, fetchRewardsPending } = useFetchRewards();
  const { fetchHarvestFeePending, fetchBulkHarvestFee } = useFetchHarvestFee();
  // const { poolsTvl } = usePoolsTvl(pools);
  // const { userTvl } = useUserTvl(pools, tokens);
  // const classes = useStyles();

  useEffect(() => {
    fetchApys();
    const id = setInterval(fetchApys, FETCH_INTERVAL_MS);
    return () => clearInterval(id);
  }, [fetchApys]);

  useEffect(() => {
    const fetch = () => {
      if (address && web3 && !fetchBalancesPending) {
        fetchBalances({ address, web3, tokens });
        fetchRewards({ address, web3, pools });
      }
      if (!fetchVaultsDataPending) {
        fetchVaultsData({ web3, pools, address });
        if (address) {
          fetchStakeData({ web3, pools, address });
        }
      }
      if (!fetchHarvestFeePending) {
        fetchBulkHarvestFee({ address, web3, pools });
      }
    };
    fetch();

    const id = setInterval(fetch, FETCH_INTERVAL_MS);
    return () => clearInterval(id);

    // Adding tokens and pools to this dep list, causes an endless loop, DDoSing the api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, web3, fetchBalances, fetchVaultsData]);

  // const chainNameLowercase = getNetworkFriendlyName().toLowerCase();

  // const activePoolCount = pools.filter(pool => pool.status === 'active').length;

  return (
    <VisiblePools
      pools={pools}
      apys={apys}
      tokens={tokens}
      fetchBalancesDone={fetchBalancesDone}
      fetchApysDone={fetchApysDone}
      fetchVaultsDataDone={fetchVaultsDataDone}
      rewards={rewards}
      fetchRewardsPending={fetchRewardsPending}
    />
  );
}
