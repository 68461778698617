const styles = theme => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  overlayBackground: {
    background: theme.palette.background.overlayCustom,
  },

  background: {
    // backgroundColor: theme.palette.background.overlayCustom,
    backgroundColor: 'rgba(51,133,255, 0.3)',
    borderRadius: '30px',
    height: '15px',
    overflow: 'hidden'
  },
  backgroundPassed: {
    background:'#3385FF',
    height: '100%',
    opacity: 0.6
  },
  mainColor: {
    color:'#9F9F9F',
    fontSize: '0.8rem',
    opacity: 0.8

  },
  accountBoxBackground: {
    background: theme.palette.background.accountContent,
  },
});

export default styles;
