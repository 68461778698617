import React, { useCallback, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import { networkSettings, networkSetup } from 'common/networkSetup';
import { getNetworkAppUrl, getNetworkFriendlyName } from 'features/helpers/getNetworkData';
import { makeStyles } from '@material-ui/core/styles';
import AccountInfo from '../AccountInfo/AccountInfo';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);
const targetNetworkId = window.REACT_APP_NETWORK_ID;

export function NetworkConnectNotice({
  web3,
  address,
  networkId,
  connectWallet,
  disconnectWallet,
}) {
  const [networkSetupError, setNetworkSetupError] = useState(null);
  const { t } = useTranslation();
  const haveConnection = !!web3;
  const haveAddress = !!address;
  const isCorrectNetwork = networkId === targetNetworkId;
  const isSupportedNetwork = networkId && networkId in networkSettings;
  const targetNetworkFriendlyName = getNetworkFriendlyName();
  const classes = useStyles();
  let notice = null;

  const targetNetworkSetup = useCallback(() => {
    setNetworkSetupError(null);

    networkSetup(targetNetworkId)
      .then(() => {
        setNetworkSetupError(null);
      })
      .catch(e => {
        if (typeof e === 'object' && typeof e.message === 'string') {
          setNetworkSetupError(e.message);
        } else if (typeof e === 'string') {
          setNetworkSetupError(e);
        } else {
          setNetworkSetupError(t('Network-UnknownError'));
        }
      });
  }, [setNetworkSetupError, t]);

  const networkRedirect = url => {
    window.location.assign(url);
    window.location.reload();
  };

  const supportedNetwork = useMemo(() => {
    return isSupportedNetwork
      ? {
          id: networkId,
          url: getNetworkAppUrl(networkId),
          name: getNetworkFriendlyName(networkId),
        }
      : null;
  }, [isSupportedNetwork, networkId]);

  if (!haveConnection) {
    notice = (
      <>
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          Connect your wallet to start using Beli
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '16px',
            cursor: 'pointer',
          }}
        >
          <div
            onClick={connectWallet}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 10px 0 20px',
              height: '50px',
              width: '150px',
              background: '#3385FF',
              borderRadius: '40px',
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
                lineHeight: '24px',
                color: '#fff',
              }}
            >
              Connect
            </span>
            <img
              src={require('assets/img/arrow-right.svg')}
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                background: 'white',
                padding: '5px',
              }}
              alt=""
            />
          </div>
        </div>
        <div
          style={{
            marginTop: '16px',
            marginBottom: '8px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '18px',
          }}
        >
          Don't have wallet setup?
        </div>
        <a
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '18px',
          }}
          href="https://metamask.io/download"
          target="_blank"
          rel="noopener noreferrer"
        >
          Create wallet
        </a>
      </>
    );
  } else if (!isCorrectNetwork) {
    notice = (
      <>
        <div className={classes.message}>
          {t('Network-Supports', { network: targetNetworkFriendlyName })}{' '}
          {isSupportedNetwork
            ? t('Network-ConnectedTo', { network: supportedNetwork.name })
            : t('Network-ConnectedUnsupported')}
        </div>
        <div className={classes.actions}>
          <Button onClick={targetNetworkSetup} className={classes.button}>
            {t('Network-SwitchToNetwork', { network: targetNetworkFriendlyName })}
          </Button>
          {isSupportedNetwork ? (
            <Button
              onClick={() => networkRedirect(supportedNetwork.url)}
              className={classes.button}
            >
              {t('Network-GoToApp', { network: supportedNetwork.name })}
            </Button>
          ) : null}
          <Button onClick={disconnectWallet} className={classes.button}>
            {t('Network-DisconnectWallet')}
          </Button>
        </div>
        <div className={classes.note}>{t('Network-SwitchNote')}</div>
        {networkSetupError ? <div className={classes.error}>{networkSetupError}</div> : ''}
      </>
    );
  } else if (!haveAddress) {
    notice = (
      <>
        <div className={classes.message}>
          {t('Network-ConnectedTo', { network: targetNetworkFriendlyName })}
        </div>
        <div className={classes.error}>{t('Network-NoWalletAddress')}</div>
      </>
    );
  }

  return notice ? <div className={classes.notice}>{notice}</div> : <AccountInfo />;
}
