import React from 'react';
import Pools from 'features/vault/components/Pools/Pools';
import { useConnectWallet } from './redux/hooks';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import { useDialogState } from '../vault/redux/hooks';
import Button from 'components/CustomButtons/Button.js';
import styles from './styles';
const useStyles = makeStyles(styles);

export default function HomePage() {
  const {
    // eslint-disable-next-line no-unused-vars
    networkId,
  } = useConnectWallet();
  const classes = useStyles();
  const { dialogState, updateDialogState } = useDialogState();

  const handleClose = () => {
    updateDialogState(false);
  };

  const handleCloseAndProceed = () => {
    updateDialogState(false);
    dialogState.confirmButtonCallback.call();
  };

  const ConfirmDialog = () => (
    <Dialog
      open={dialogState?.isOpen}
      maxWidth={'sm'}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        className={classes.dialogbg}
        style={{ borderTopLeftRadius: '30px', borderTopRightRadius: '30px' }}
      >
        <DialogContentText id="alert-dialog-description">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {dialogState?.contentRenderer && dialogState.contentRenderer()}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className={classes.dialogbg}
        style={{
          borderBottomLeftRadius: '30px',
          borderBottomRightRadius: '30px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          paddingBottom: '20px',
        }}
      >
        <Button
          style={{
            borderRadius: '20px',
            fontSize: '16px',
            lineHeight: '24px',
            backgroundColor: '#2A2A2A',
            minWidth: '130px',
            fontWeight: '600',
            textTransform: 'capitalize',
          }}
          onClick={handleClose}
        >
          <span style={{ color: 'white' }}>Cancel</span>
        </Button>
        <Button
          style={{
            borderRadius: '20px',
            fontSize: '16px',
            lineHeight: '24px',
            backgroundColor: '#3385FF',
            minWidth: '130px',
            fontWeight: '600',
            textTransform: 'capitalize',
          }}
          onClick={handleCloseAndProceed}
          autoFocus
        >
          <span style={{ color: 'white' }}>{dialogState?.confirmButtonTitle}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Pools fromPage="home" />
      <ConfirmDialog></ConfirmDialog>
    </>
  );
}
