import React, { useMemo, useCallback } from 'react';
// import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { allNetworks } from '../../network';
import { getSingleAssetSrc } from '../../features/helpers/getSingleAssetSrc';
import styles from './styles';

const useStyles = makeStyles(styles);

const ChainSelection = () => {
  // const { t } = useTranslation();
  const classes = useStyles();
  const currentNetwork = useMemo(
    () => allNetworks.find(network => network.id === window.REACT_APP_NETWORK_ID),
    []
  );

  const handleNetworkClick = useCallback(network => {
    window.location.hash = network.hash;
    window.location.reload();
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <div className="dashboard-title">
          <div>
            <h1 className={classes.title}>Dashboard</h1>
          </div>
          <div className="dashboard-title__tvl">
            {/* <Hidden mdUp>
              <HeaderTVLInfo />
            </Hidden> */}
          </div>
        </div>
      </Grid>
      <div className="network-list" style={{ display: 'flex' }}>
        {allNetworks.map(network => (
          <div
            className={`network-list_item ${
              currentNetwork.id === network.id ? 'network-list_item--active' : ''
            }`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '10px 16px',
              borderRadius: '25px',
            }}
            onClick={() => handleNetworkClick(network)}
          >
            <img
              src={getSingleAssetSrc(network.asset)}
              alt=""
              style={{
                width: '20px',
                height: '20px',
              }}
            />
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '30px',
                marginLeft: '13px',
                color: 'white',
              }}
            >
              {network.name}
            </span>
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default ChainSelection;
