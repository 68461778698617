const styles = theme => ({
  scroller: {
    width: '100%',
  },
  subtitle: {
    margin: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '21px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '16px',
    },
    color: theme.palette.text.primary,
  },
  overlayBackground: {
    background: theme.palette.background.overlayCustom,
  },
});

export default styles;
