import React from 'react';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mark: {
    height: '4px',
    width: '2px',
  },
  markLabel: {
    color: theme.palette.text.primary,
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '12px',
  },
  valueLabel: {
    '& > span': {
      color: theme.palette.text.primary,
    },
  },
  rail: {
    color: theme.palette.text.primary,
    borderRadius: '3px',
    height: '2px',
  },
  thumb: {
    color: theme.palette.text.primary,
  },
  track: {
    color: theme.palette.text.primary,
    height: '4px',
    width: '2px',
  },
  active: {
    color: theme.palette.text.primary,
  },
}));

export default function CustomSlider(props) {
  const classes = useStyles();
  return (
    <Slider
      classes={{
        mark: classes.mark,
        markLabel: classes.markLabel,
        valueLabel: classes.valueLabel,
        rail: classes.rail,
        thumb: classes.thumb,
        track: classes.track,
        active: classes.active,
      }}
      defaultValue={0}
      min={0}
      max={100}
      size="small"
      valueLabelDisplay="auto"
      marks={[
        { value: 0, label: '0%' },
        { value: 25, label: '25%' },
        { value: 50, label: '50%' },
        { value: 75, label: '75%' },
        { value: 100, label: '100%' },
      ]}
      {...props}
    />
  );
}
