import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Countdown from 'react-countdown';

import { byDecimals } from 'features/helpers/bignumber';

import { useFetchHarvest, useFetchHarvestFee, useDialogState } from 'features/vault/redux/hooks';
import { useConnectWallet } from 'features/home/redux/hooks';
import styles from './styles';
import { formatTvl } from 'features/helpers/format';
import { fetchPrice } from 'features/web3';
import { useFetchLocker } from '../../../redux/fetchLocker';
import BigNumber from 'bignumber.js';

const useStyles = makeStyles(styles);

const HarvestSection = ({
  pool,
  index,
  pendingRewards,
  pendingFeeReceiver,
  pendingConvertedEarn,
  fetchRewardsPending,
}) => {
  const { t } = useTranslation();
  const { fetchUserLocker } = useFetchLocker();
  const classes = useStyles();
  const { web3, address } = useConnectWallet();
  const { enqueueSnackbar } = useSnackbar();
  const {
    fetchHarvest,
    // fetchHarvestPending,
  } = useFetchHarvest();
  const { fetchHarvestFee, fetchHarvestFeePending, fetchHarvestFeeDone, harvestFeeUntil } =
    useFetchHarvestFee();
  const [
    // showHarvestModal,
    setShowHarvestModal,
  ] = useState(false);
  const price = fetchPrice({ id: 'BELI' });
  const totalRewards = pendingRewards.plus(pendingFeeReceiver).plus(pendingConvertedEarn);
  // needed fbecause for some reason re-render will happen and Date.now()
  // will then point to a new value, if someone have a better fix then feel free
  const [now, setNow] = useState(undefined);

  const {
    // dialogState,
    updateDialogState,
  } = useDialogState();

  const handleClickOpen = () => {
    updateDialogState(
      true,
      () => (
        <>
          <img
            alt="launchpool how to"
            src={require('assets/img/warning.png')}
            style={{ width: '100px', marginBottom: '20px' }}
          />
          <div className={classes.dialogtext} style={{ marginBottom: '20px', fontSize: '26px' }}>
            Harvest Warning!
          </div>
          <div style={{ textAlign: 'center', padding: '0 20px', fontSize: '16px' }}>
            Harvest before the lock timer has ended will trigger{' '}
            <strong style={{ color: 'rgb(240, 123, 123)' }}>50%</strong> harvest fee. Are you sure?
          </div>
        </>
      ),
      'Yes, Harvest',
      onHarvest
    );
  };

  useEffect(() => {
    if (
      web3 &&
      address &&
      !fetchHarvestFeePending &&
      byDecimals(pendingRewards, 18).gt(0) &&
      typeof harvestFeeUntil[pool.id] === 'undefined'
    ) {
      fetchHarvestFee({ web3, address, pool });
    }
    if (typeof now === 'undefined') setNow(Date.now());
  }, [
    web3,
    address,
    fetchHarvestFee,
    fetchHarvestFeePending,
    harvestFeeUntil,
    pool,
    setNow,
    now,
    pendingRewards,
  ]);

  const onHarvest = () => {
    fetchHarvest({
      address,
      web3,
      contractAddress: pool.earnContractAddress,
      index,
      pid: pool.pid,
    })
      .then(() => {
        if (web3 && address) {
          fetchUserLocker({ web3, address });
          fetchHarvestFee({ web3, address, pool });
        }
        return enqueueSnackbar(t('Vault-HarvestSuccess'), { variant: 'success' });
      })
      .catch(error => enqueueSnackbar(t('Vault-HarvestError', { error }), { variant: 'error' }));
    setShowHarvestModal(false);
  };
  const farm = {
    withdrawalFeeBP: pool.harvestFee,
    // withdrawalFeeInterval: 8,
  };
  const due = typeof harvestFeeUntil[pool.id] !== 'undefined' ? harvestFeeUntil[pool.id] * 1000 : 0;
  const isShowCountdown = due > now;

  const rendererWithdrawCountdown = ({ days, hours, minutes, seconds }) => {
    return (
      <div className={classes.harvestInfo} style={{ marginTop: '1rem' }}>
        A <b className={classes.hightLightFee}>{farm.withdrawalFeeBP}%</b> harvest fee is deducted
        from the <strong>pending rewards</strong> of{' '}
        <strong>{byDecimals(pendingRewards, 18).toFormat(2, BigNumber.ROUND_DOWN)} BELI</strong> if
        the harvest occurs within {days} day(s) {hours} hour(s) {minutes} minute(s) {seconds}{' '}
        second(s).
        <br />
        <br />
        This harvest fee is automatically sent to burn address.
      </div>
    );
  };

  return (
    <Grid item xs={12} md={4} className={classes.sliderDetailContainer}>
      <div
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <span className={classes.subtitle} style={{ marginBottom: '10px' }}>
          BELI Rewards
        </span>
        <span className={classes.title} style={{ marginBottom: '10px' }}>
          {fetchRewardsPending && !pendingRewards
            ? '???'
            : byDecimals(totalRewards, 18).toFormat(8, BigNumber.ROUND_DOWN)}
        </span>
        <span className={classes.subtitle} style={{ marginBottom: '10px' }}>
          {formatTvl(byDecimals(pendingRewards, 18), price)}
        </span>
        {totalRewards.gt(0) ? (
          <a
            className={classes.url}
            href="javascript:void(0)"
            style={{ marginBottom: '10px' }}
            onClick={() => {
              isShowCountdown ? handleClickOpen() : onHarvest();
            }}
          >
            Harvest
          </a>
        ) : (
          <span>Harvest</span>
        )}

        {byDecimals(pendingRewards, 18).gt(0) && fetchHarvestFeeDone && isShowCountdown ? (
          <Countdown date={due} renderer={rendererWithdrawCountdown} />
        ) : (
          <></>
        )}
      </div>
    </Grid>
  );
};

export default HarvestSection;
