import React, { forwardRef, memo } from 'react';

const HeaderLevelInfo = forwardRef(({ value, label, subvalue, imgSrc, ...passthrough }, ref) => {
  return (
    <div {...passthrough} ref={ref}>
      <img
        alt="level"
        src={imgSrc}
        style={{
          width: '18px',
          height: '18px',
          marginLeft: '10px',
          marginBottom: '3px',
        }}
      ></img>
    </div>
  );
});

export default memo(HeaderLevelInfo);
